import { useState, useEffect, useContext, useRef} from "react";
import Reload from "../../../share/componets/reload";
import { 
    BsArrowRepeat, 
    BsBoxArrowRight, 
    BsCalendar2, 
    BsCaretDownFill, 
    BsClipboardCheck, 
    BsHouseDoor, 
    BsJustify 
} from "react-icons/bs";
import {  
    getDayMonthYearToday, 
    getDayNowCalendar, 
    getMonthDates, 
    getFirstThreeLetters, 
    getOfficeHourAndSchedulingsCollaboratorLogin,
    getBilligCollaborator
} from "../functions";
import { onLimitCharacters, loadNameMonth } from "../../../share/functions";
import AuthContext from "../../../context/AuthContext";
import SideBar from "../../../componentes/sidebar";
import { useNavigate } from "react-router-dom";
import GenerateTimeSlots from "../../../lib/GenerateTimeSlots";
import ModalCalendar from "./modal-calendar";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import ModalSchedulig from "./modal-scheduling";
import ModalAddScheduling from "./modal-addscheduling";

export default function Collaborators()
{
    const {user, logout} = useContext(AuthContext);
    const [reload, setReload] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [modalCalendarOpen, setModalCalendarOpen] = useState(false);
    const [modalSchedulingOpen, setModalSchedulingOpen] = useState(false);
    const [modalAddSchedulingOpen, setModalAddSchedulingOpen] = useState(false);
    const [collaboratorsList, setCollaboratorsList] = useState([]);
    const [selectCollaborator, setSelectCollaborator] = useState(null);
    const [selectMonthString, setSelectMonthString] = useState('');
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [daysToMonthList, setDaysToMonthList] = useState([]);
    const [selectDay, setSelectDay] = useState(null);
    const [visibleBilling, setVisibleBilling] = useState(true);
    const [timesSlotsList, setTimesSlotsList] = useState([]);
    const [selectDateNow, setSelectDateNow] = useState(null);
    
    const [copyed, setCopied] = useState(false);

    const navigate = useNavigate();

    const carrousselRef = useRef(null);
    const itemRefs = useRef({});

    const [billing, setBilling] = useState({

        dailyBilling: 'R$ 0.00',
        weeklyBilling: 'R$ 0.00',
        monthlyBilling: 'R$ 0.00',
        dailyAppointmentsCount: 0,
        weeklyAppointmentsCount: 0,
        monthlyAppointmentsCount: 0,
    })

    async function loadBilling()
    {
         const response = await getBilligCollaborator(user.id);
 
         setBilling({
             ...billing,
             dailyBilling: response.dailyBilling,
             dailyAppointmentsCount: response.dailyAppointmentsCount,
             weeklyBilling: response.weeklyBilling,
             weeklyAppointmentsCount: response.weeklyAppointmentsCount,
             monthlyBilling: response.monthlyBilling,
             monthlyAppointmentsCount: response.monthlyAppointmentsCount
         })
    }

    const [scheduleShow, setScheduleShow] = useState({
        id: null,
        clientName: '',
        collaboratorName: '',
        time: '',
        services: [],
        value: '',
        status: '',
        phone: '',
        dateTime: '',
        collaboratorId: ''
    })
  

    async function changeSlotsDate(dateNow)
    {
        const dateNowFormated = {
            dayOfWeek: dateNow.name,
            day: dateNow.day,
            month: dateNow.month,
            year: dateNow.year
        };

        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        setSelectDay(dateNow.day)
        const daysToMonth = getMonthDates(dateNow.year, dateNow.month);
        setDaysToMonthList(daysToMonth);
        setSelectDateNow(dateNowFormated);
        setCalendarDate(null)
        loadOfficeHoursAndSchedulingsCollaborator(dateNowFormated);
    }

    useEffect(()=>{
        loadDataCollaboratorNow();
        loadBilling();
    }, [])

    useEffect(() => {
        // Se um dia está selecionado e o item correspondente existe
        if (selectDay && itemRefs.current[selectDay]) {
            const selectedItem = itemRefs.current[selectDay];
            const carroussel = carrousselRef.current;
    
            // Calcula a posição de scroll necessário para que o item selecionado esteja visível
            const scrollLeft = selectedItem.offsetLeft + selectedItem.offsetWidth / 2 - carroussel.offsetWidth / 2;
            
            // Ajusta o scroll do container do carrossel
            carroussel.scrollLeft = scrollLeft;
            }
        }, [selectDay]);

    function loadDataCollaboratorNow()
    {
         const dateNow = getDayMonthYearToday();
         setSelectDateNow(dateNow);
         setSelectMonthString(loadNameMonth(dateNow.month - 1));
         const daysToMonth = getMonthDates(dateNow.year, dateNow.month);
         setSelectDay(dateNow.day)
         setDaysToMonthList(daysToMonth);
         loadOfficeHoursAndSchedulingsCollaborator(dateNow);
    }


    async function loadOfficeHoursAndSchedulingsCollaborator(date)
    {
    
         const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsCollaboratorLogin(user.id);
 
         if(officeHoursAndSchedulings)
         {
             if(officeHoursAndSchedulings.officeHour)
             {
                 const daysOfficeHours = JSON.parse(officeHoursAndSchedulings.collaborator.days_hours);
                 const dayNow = daysOfficeHours.filter(item => item.name === getFirstThreeLetters(date.dayOfWeek));
                 const intervals = officeHoursAndSchedulings.collaborator.intervals ? JSON.parse(officeHoursAndSchedulings.collaborator.intervals) : [];
                 const generatorSlotsSchedulings = new GenerateTimeSlots(
                     dayNow[0]?.start,
                     dayNow[0]?.end, 
                     parseInt(officeHoursAndSchedulings.officeHour.service_interval), 
                     dayNow[0]?.start,
                     officeHoursAndSchedulings.scheduling, 
                     intervals,
                     date
                 )
                 const timesSlot = generatorSlotsSchedulings.create();
 
                 setTimesSlotsList(timesSlot);
                 
             }else{
             
             }
         }
    }

    async function changeCalendar(date)
    {
        setCalendarDate(date)
        setSelectDateNow(null);

        const dateNow = getDayNowCalendar(date);
        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        setSelectDay(dateNow.day);
        setSelectDateNow(dateNow);

        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        const daysToMonth = getMonthDates(dateNow.year, dateNow.month);
        setSelectDay(dateNow.day)
        setDaysToMonthList(daysToMonth);
        loadOfficeHoursAndSchedulingsCollaborator(dateNow);

        setModalCalendarOpen(false);
    }


    async function loadUpdate()
    {
        setSelectMonthString(loadNameMonth(selectDateNow.month - 1));
        const daysToMonth = getMonthDates(selectDateNow.year, selectDateNow.month);
        setSelectDay(selectDateNow.day)
        setDaysToMonthList(daysToMonth);
        loadOfficeHoursAndSchedulingsCollaborator(selectDateNow);
    }

    function schudulingShow(schedule)
    {
        setScheduleShow((prev) => ({
            ...prev,
            id: schedule.id,
            clientName: schedule.client.person.name,
            collaboratorName: schedule.collaborators.person.name,
            time: JSON.parse(schedule.hour).time,
            services: schedule.services,
            value: schedule.value,
            status: schedule.status,
            phone: schedule.client.person.phone,
            dateTime: schedule.appointment_date,
            collaboratorId: schedule.colaborator_id,
            clientId: schedule.client.id,
            clientBlock: schedule.client.block_at 

        }));

        setModalSchedulingOpen(true);
    }

    return(

        <div className="collaborator-container">

            {reload && <Reload bg={'rgba(0,0,0,0.8)'}/>}

            {sideBarOpen &&
                <SideBar
                    sideBarOpen={sideBarOpen} 
                    setSideBarOpen={setSideBarOpen}
                />
            }

            {modalCalendarOpen && 
                <ModalCalendar
                    modalCalendarOpen={modalCalendarOpen}
                    setModalCalendarOpen={setModalCalendarOpen}
                    setCalendarDate={setCalendarDate}
                    calendarDate={calendarDate}
                    changeCalendar={changeCalendar}
                />            
            }

            {modalSchedulingOpen &&
                <ModalSchedulig
                
                    setModalSchedulingOpen={setModalSchedulingOpen}
                    modalSchedulingOpen={modalSchedulingOpen}
                    scheduleShow={scheduleShow}
                    setScheduleShow={setScheduleShow}
                    selectDateNow={selectDateNow}
                    changeSlotsDate={changeSlotsDate}
                    calendarDate={calendarDate}
                    changeCalendar={changeCalendar}
                    daysToMonthList={daysToMonthList}
                />            
            }

            {modalAddSchedulingOpen &&
                <ModalAddScheduling
                    modalAddSchedulingOpen={modalAddSchedulingOpen}
                    setModalAddSchedulingOpen={setModalAddSchedulingOpen}
                    daysToMonthList={daysToMonthList}
                    collaboratorsList={collaboratorsList}
                    user={user}
                />            
            }

            <div className="collaborator-container-header">

                <button onClick={()=>{setSideBarOpen(!sideBarOpen)}} className="btn-menu">
                    <BsJustify size={25} color="#fff" />
                </button>

                <h3>
                    Início
                </h3>

                <button onClick={()=>{logout()}} className="btn-transparent">
                    <BsBoxArrowRight size={25} color="#fff" />
                </button>

            </div>

            <div className="collaborator-container-user">

                <img src="./images/jadde_home.png"/>

                <h3>Olá, {user.name}!</h3>

                <div></div>

            </div>

            <div className="collaborator-container-link">

                <span className="link-mobile"><span className="f-bold" >Seu Link:</span> {onLimitCharacters(user.link, 20)}</span>
                <span className="link-desktop"><span className="f-bold" >Seu Link de agendamento:</span> {onLimitCharacters(user.link, 41)}</span>
               
                <CopyToClipboard text={selectCollaborator ? selectCollaborator.link : user.link} onCopy={() => setCopied(true)}>
                    <button className="btn-copy">
                       {copyed === false ? "Copiar link" : "Copiado"} {copyed === false ?  <MdContentCopy size={25} color="#fff"/> : <BsClipboardCheck size={25} color="#fff"/>}
                    </button>
                </CopyToClipboard>

            </div>

            <div className="collaborator-container-calendar">

                <button onClick={()=>{setModalCalendarOpen(true)}} className="btn-transparent">
                    <BsCalendar2 size={25} color="#fff"/>
                    <h3 className="ml-20">{selectMonthString}</h3>
                </button>

            </div>

            <div ref={carrousselRef} className="collaborator-container-carroussel">

                {daysToMonthList.map((item, index)=>{
                    return(
                        <div 
                            ref={el => itemRefs.current[item.day] = el} 
                            onClick={()=>{changeSlotsDate(item)}} 
                            key={index} 
                            className={selectDay === item.day ? "item-date-active" : "item-date"}
                        >
                            <div className="item-date-content">
                                <p>{item.name}</p>
                                <p>{item.day}</p>
                            </div>
                        </div>
                    )
                })}

            </div>

            <div className="collaborator-container-btn-billing-collapse">

                <button onClick={()=>{setVisibleBilling(!visibleBilling)}} className="">
                    <span>Esconder faturamento</span>
                    {visibleBilling === true ?  <MdKeyboardArrowUp size={25} color="#fff" /> : <MdKeyboardArrowDown size={25} color="#fff" />}
                
                </button>

            </div>

            <div className={visibleBilling === true ? "collaborator-container-biling-day-weekly" : "hidden"}>

                <div className="card-faturament-quite">
                    <div className="d-flex">
                        <h3>Hoje</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.dailyBilling}</h3>
                    <span>Quantidade {billing.dailyAppointmentsCount}</span>
                </div>

                <div className="card-faturament-quite">
                    <div className="d-flex">
                        <h3>Semana</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.weeklyBilling}</h3>
                    <span>Quantidade {billing.weeklyAppointmentsCount}</span>
                </div>

            </div>

            <div className={visibleBilling === true ? "collaborator-container-biling-month" : "hidden"}>
                <div className="card-faturament">
                    <div className="d-flex">
                        <h3>Mês</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.monthlyBilling}</h3>
                    <span>Quantidade {billing.monthlyAppointmentsCount}</span>
                </div>
            </div>

            <div className="container-center mt-20">
               {timesSlotsList.length === 0 && <span>Este dia ainda não tem horário expediente cadastrado</span>} 
            </div>

            <div className="collaborator-container-btn-update">
                <button onClick={()=>{loadUpdate()}} className="btn-update">
                    Atualizar agendamentos
                    <BsArrowRepeat size={25} color="#fff" />
                </button>
            </div>

            <div className="collaborator-container-schedulings">

                <ul>
                    {timesSlotsList.map((item, index)=>{
                        return(
                                <li key={index}>
                                    <span>{item.time}</span>
                                    {item.schedulings.map((schedule, scheduleIndex)=>{
                                        return(
                                            <div onClick={()=>{schudulingShow(schedule)}} key={scheduleIndex} className={schedule.status === "Pendente" ? "card-pending" : schedule.status === "Cancelado" ? "card-canceled" : "card-concluded" }>
                                                <p><span>Cliente: </span> {schedule.client.person.name}</p>
                                                <p><span>Colaborador: </span> {schedule.collaborators.person.name}</p>
                                            </div>
                                        )
                                    })}
                                </li>
                        )
                    })}
                </ul>

            </div>

            <div className="form">
                <button onClick={()=>{setModalAddSchedulingOpen(true)}} className="btn">+ Adicionar agendamento</button>
            </div>

            <div className="mt-50"></div>

        </div>
    )
}