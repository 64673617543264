import { useEffect, useRef, useState } from "react"
import { BsCircle, BsCircleFill, BsPencilSquare, BsThreeDots } from "react-icons/bs"
import { formatDateTime, formatObjInDate, genereteTimes, getClients, getClientsByCollabortor, getCollaborators, getDayMonthYearToday, getOfficeHourAndSchedulingsCollaborator, getOfficeHourCollaborator, storeScheduling, transformStringDate } from "../functions"
import Reload from "../../../share/componets/reload";
import Validator from "../../../lib/Validator";
import {isTodayBeforeOrEqualToGivenDate } from "./functions";

export default function ModalAddScheduling({
    modalAddSchedulingOpen, 
    setModalAddSchedulingOpen, 
    daysToMonthList,
    user
})
{
    const [reload, setReload] = useState(false);

    const itemRefs = useRef({});

    const carrousselRef = useRef(null);

    const [errors, setErrors] = useState({
        clientId: '',
        colaboratorId: '',
        hour: '',
        appointmentDate: '',
        services: '',
        code: '',
        time: '',
        date:''
    })

    const [piker, setPiker] = useState({

        collaborator: {name: "Selecione um colaborador"},
        date: {day: ''},
        time: '',
        day: null,
        daysHoursCollaborator: null,
        schedulingsCollaborator: null,
        adminOfficeHour: null,
        services: [],
        client: {name: "Selecione um cliente"},
        intervals: []
    })

    const [list, setList] = useState({

        collaborators: [],
        times: [],
        daysHours: [],
        services: [],
        clients: []
    })

    useEffect(() => {
        
        // Se um dia está selecionado e o item correspondente existe

        if (piker.date && itemRefs.current[piker.date.day]) {


            const selectedItem = itemRefs.current[piker.date.day];
            const carroussel = carrousselRef.current;
    
            // Calcula a posição de scroll necessário para que o item selecionado esteja visível
            const scrollLeft = selectedItem.offsetLeft + selectedItem.offsetWidth / 2 - carroussel.offsetWidth / 2;
            
            // Ajusta o scroll do container do carrossel
            carroussel.scrollLeft = scrollLeft;
        }

    }, [piker.date]);

    useEffect(()=>{

        loadCollaborators();
        const dateNow = getDayMonthYearToday();
        setPiker((prev)=>({
            ...prev,
            date: dateNow
        }));

    }, [])

    async function loadCollaborators()
    {
        if(user.type === "admin")
        {
            const result = await getCollaborators(user.id);
        
            setList((prev)=>({
                ...prev,
                collaborators: result
            }));

        }else{

            loadCollaboratorData();
        }

    }

    async function loadCollaboratorData()
    {
        const response = await getOfficeHourCollaborator(user.id);

        const collaborator = response.collaborator;
        const daysHoursCollaborator = JSON.parse(collaborator.days_hours);
        const adminOfficeHour = response.officeHour
        const schedulingsCollaborator = response.scheduling;

        const intervals = response.collaborator.intervals ? JSON.parse(response.collaborator.intervals) : [];

        const dateNow = getDayMonthYearToday();

        const timesSlots = genereteTimes(
            dateNow, 
            daysHoursCollaborator, 
            schedulingsCollaborator, 
            adminOfficeHour,
            intervals
        );

        const services = JSON.parse(response.collaborator.services);

        const clients = await getClientsByCollabortor(user.id);

        setList((prev) => ({
            ...prev,
            times: timesSlots,
            services: services,
            clients: clients ? clients : []
        }))

        setPiker((prev) => ({
            ...prev,
            collaborator: collaborator,
            day: dateNow.dayOfWeek.substring(0,3),
            daysHoursCollaborator: daysHoursCollaborator,
            schedulingsCollaborator: schedulingsCollaborator,
            adminOfficeHour: adminOfficeHour,
        }))

        return


    }

    async function changeCollaborator(index)
    {
        if(index !== "Selecione um colaborador")
        {
            const collaborator = list.collaborators[index];
            const daysHoursCollaborator = JSON.parse(collaborator.days_hours);
            const adminAndCollaboratorData = await getOfficeHourAndSchedulingsCollaborator(user.id, collaborator.id);
            const adminOfficeHour = adminAndCollaboratorData.officeHour
            const schedulingsCollaborator = adminAndCollaboratorData.scheduling;
            const dateNow = getDayMonthYearToday();
            const intervalCollaborator = adminAndCollaboratorData.collaboratorIntervals.filter(item => item.id === collaborator.id);
            const intervals = intervalCollaborator[0]?.intervals ? JSON.parse(intervalCollaborator[0]?.intervals) : [];

            const timesSlots = genereteTimes(
                dateNow, 
                daysHoursCollaborator, 
                schedulingsCollaborator, 
                adminOfficeHour,
                intervals
            );
            
            const services = JSON.parse(adminAndCollaboratorData.collaborator.services);
            const clients = await getClients(user.id);

            setList((prev) => ({
                ...prev,
                times: timesSlots,
                services: services,
                clients: clients ? clients : []
            }))

            setPiker((prev) => ({
                ...prev,
                collaborator: collaborator,
                day: dateNow.dayOfWeek.substring(0,3),
                daysHoursCollaborator: daysHoursCollaborator,
                schedulingsCollaborator: schedulingsCollaborator,
                adminOfficeHour: adminOfficeHour,
                intervals: intervals
            }))

            return
        }

        setPiker((prev) => ({
            ...prev,
            collaborator: {name:"Selecione um colaborador"}
        }))

    }

    function changeClient(index)
    {
        const client = list.clients[index]
        setPiker((prev) => ({
            ...prev,
            client: client
        }))
    }

    function changeDay(item)
    {
        const timesSlots = genereteTimes(
            item, 
            piker.daysHoursCollaborator, 
            piker.schedulingsCollaborator, 
            piker.adminOfficeHour,
            piker.intervals
        );

        setList((prev) => ({
            ...prev,
            times: timesSlots
        }))

        setPiker((prev) => ({
            ...prev,
            date: item
        }))
    }

    function changeTimes(time) {
        setPiker(prev => ({
            ...prev,
            time: time
        }));
    }

    function changeServices(id)
    {
        setPiker(prev => {
            const array = prev.services.includes(id)
                ? prev.services.filter(t => t !== id) // Remove o item se já estiver presente.
                : [...prev.services, id]; // Adiciona o item se não estiver presente.
    
            return { ...prev, services: array };
        });
    }

    async function onSave()
    {

        const code = crypto.randomUUID();

        const DATA = {
            clientId: piker.client.client_id,
            colaboratorId: piker.collaborator.id,
            hour: {date: formatObjInDate(piker.date), time: piker.time},
            appointmentDate: formatDateTime(piker.date, piker.time),
            services: piker.services,
            code: code
        }

        const rules = {
            clientId: 'required',
            colaboratorId: 'required',
            hour: 'required',
            appointmentDate: 'required',
            services: 'required',
            code: 'required',
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({
            'clientId.required':        'Você tem que escolher um cliente.',
            'collaboratorId.required':  'Você tem que escolher um colaborador.',
            'hour.required':            'Você tem que selecionar um horário.',
            'appointmentDate.required': 'Você tem que selecionar uma data e horário.',
            'services.required':        'Você tem que escolhar ao menos um serviço.',
            'code.required' :           'Ops Algo de errado em seus dados'
        });

        const validationErrors = validator.validate(DATA);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return
        } else {
            console.log("Formulário válido, enviar dados:", DATA);

            setErrors({

                clientId: '',
                colaboratorId: '',
                hour: '',
                appointmentDate: '',
                services: '',
                code: '',
            });
        }

        if(!piker.date)
        {
            setErrors((prev) => ({
                ...prev,
                date: 'Você tem que escolher uma data'
            }))
            return
        }
        if(!piker.time)
        {
            setErrors((prev) => ({
                ...prev,
                time: 'Você tem que escolher um horário'
            }))
            return
        }
        if(piker.services.length < 1)
        {
            setErrors((prev) => ({
                ...prev,
                services: 'Você tem que escolher ao menos um serviço'
            }))
            return
        }

        setReload(true)

        const response = await storeScheduling(DATA);

        if(response)
        {
            setPiker({
                collaborator: {name: "Selecione um colaborador"},
                date: getDayMonthYearToday(),
                time: '',
                day: null,
                daysHoursCollaborator: null,
                schedulingsCollaborator: null,
                adminOfficeHour: null,
                services: [],
                client: {name: "Selecione um cliente"}
            })

            setList((prev) => ({
                ...prev,
                times: [],
                daysHours: [],
            }))

            // Usando postMessage para enviar dados para o aplicativo React Native
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'scheduleStore',
                    data: DATA
                }));
            }


            setTimeout(() => {
                setReload(false);
                setModalAddSchedulingOpen(false);
            }, 2000);

        }

    }

    function editCollaborator()
    {
        setPiker((prev) => ({
            ...prev,
            collaborator: {name: "Selecione um colaborador"}
        }));
    }

    function editClient()
    {
        setPiker((prev) => ({
            ...prev,
            client: {name: "Selecione um cliente"}
        }));
    }   

    return(

        <div className={modalAddSchedulingOpen === true ? "modal" : "hidden"}>

            { reload && <Reload bg='rgba(0,0,0,0.7)'/>}

            <div className={modalAddSchedulingOpen === true ? "modal-content" : "hidden"}>

                <div className="modal-content-header">
                    <button onClick={()=>{setModalAddSchedulingOpen(false)}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="modal-content-title">
                    <h3>Adicionar agendamento:</h3>
                </div>

                {piker.collaborator.name !== "Selecione um colaborador" &&
                user.type === "admin" &&
                    <div className="modal-content-label">
                        <span>Colaborador: {piker.collaborator.name === "Selecione um colaborador" ? "-" : piker.collaborator.name}</span>
                        {piker.collaborator.name === "Selecione um colaborador" ? "" : <button onClick={()=>{editCollaborator()}}><BsPencilSquare size={25} color="#047C54"/></button>}
                    </div>
                }

                {piker.collaborator.name === "Selecione um colaborador" &&
                   user.type === "admin" &&
                    <div className="modal-content-form">
                        <select value={piker.collaborator.name === "Selecione um colaborador" ? piker.collaborator.name : piker.collaborator.index} onChange={(e)=>{changeCollaborator(e.target.value)}} className="modal-content-form-select">
                            <option value={"Selecione um colaborador"}>{"Selecione um colaborador"}</option>
                            {list.collaborators.map((item, index) =>{
                                return(
                                    <option value={index} key={index}>{item.name}</option>
                                )
                            })}
                        </select>

                        <span className="alert-errors">{errors.colaboratorId}</span>
                    </div>                 
                
                }

                {piker.client.name !== "Selecione um colaborador" &&
                <>
                    {piker.client.name !== "Selecione um cliente" &&
                        <div className="modal-content-label">
                            <span>Cliente: {piker.client.name === "Selecione um cliente" ? "-" : piker.client.name}</span>
                            {piker.client.name === "Selecione um cliente" ? "" : <button onClick={()=>{editClient()}}><BsPencilSquare size={25} color="#047C54"/></button>}
                        </div>
                    }

                    {piker.client.name === "Selecione um cliente" &&
                        <div className="modal-content-form">
                            <select value={piker.client.name === "Selecione um cliente" ? piker.client.name : piker.client.index} onChange={(e)=>{changeClient(e.target.value)}} className="modal-content-form-select">
                                <option value={"Selecione um cliente"}>{"Selecione um cliente"}</option>
                                {list.clients.map((item, index) =>{
                                    return(
                                        <option value={index} key={index}>{item.name}</option>
                                    )
                                })}
                            </select>
                            <span className="alert-errors">{errors.clientId}</span>
                        </div>                
                    }
                </>
                
                }
 
                {piker.collaborator.name !== "Selecione um colaborador" && piker.client.name !== "Selecione um cliente" ?
                <>
                    <div className="modal-content-label">
                        <span>Calendário:</span>
                        <span className="alert-errors">{errors.hour}</span>
                        <span className="alert-errors">{errors.date}</span>
                    </div>
                    
                    <div ref={carrousselRef} className="carousel-date">

                        {daysToMonthList.map((item, index)=>{

                            if(isTodayBeforeOrEqualToGivenDate(item) === true)
                            {
                                return(
                                    <div ref={el => itemRefs.current[item.day] = el} onClick={()=>{changeDay(item)}} key={index} className={piker.date.day === item.day ? "item-date-active" : "item-date"}>
                                        <div className="item-date-content">
                                            <p>{item.name}</p>
                                            <p>{item.day} / {item.month}</p>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                
                    </div>
                </>
                :

                    <div></div>
                }
                 {piker.collaborator.name !== "Selecione um colaborador" && piker.client.name !== "Selecione um cliente" ?
                <>
                    <div className="modal-content-label">
                        <span>Horário:</span>
                        <span className="alert-errors">{errors.hour}</span>
                        <span className="alert-errors">{errors.time}</span>
                    </div>   
                    
                    <div className="modal-content-hours">
                        <ul>
                            {list.times.map((item, index) =>{
                                if(item.available === true)
                                {
                                    return(
                                        <li 
                                            onClick={()=>{changeTimes(item.slot)}} 
                                            key={index} 
                                            className={piker.time === item.slot ? "li-active" : "li-no-active"
                                            }>
                                            {item.slot}
                                        </li>
                                    )
                                }
                            })}
                            
                        </ul>


                    </div>
                </>
                :
                <div></div>
                }

                {piker.collaborator.name !== "Selecione um colaborador" && piker.client.name !== "Selecione um cliente" ?
                <>
                
                    <div className="modal-content-label">
                        <span>Serviços:</span>
                        <span className="alert-errors">{errors.services}</span>
                    </div>   

                    <div className="modal-content-services">
                        <ul>
                            {list.services.map((item, index) =>{
                                return(
                                    <li onClick={()=>{changeServices(item.id)}} key={index}>
                                        <span>{item.name}</span>
                                        {piker.services.includes(item.id) 
                                        ? <BsCircleFill size={16} color={'#9BDDBA'} /> 
                                        : <BsCircle size={16} color={'#ccc'} />}
                                        
                                    </li>
                                )
                            })}
                            

                        </ul>

                    </div>

                    <div className="modal-content-btn">
                        <span className="alert-errors">{errors.code}</span>
                        <button onClick={()=>{onSave()}}>Agendar agora</button>
                    </div>
                
                
                </>
                : <div></div>
                
                
                }



                    <div className="mt-100"></div>
            </div>

        </div>
    )
}