import { useContext, useEffect, useState } from "react";
import SideBar from "../../componentes/sidebar";
import { BsFillPeopleFill, BsJustify, BsPencilSquare, BsTrash } from "react-icons/bs";
import ModalAddClient from "./containers/ModalAddClient";
import ModalUpdateClient from "./containers/ModalUpdateClient";
import ModalDeleteClient from "./containers/ModalDeleteClient";
import AuthContext from "../../context/AuthContext";
import { findClient, getClients,  getServices } from "./api";
import { FaWhatsapp } from "react-icons/fa";



export default function Client()
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const [clientsList, setClientsList] = useState([]);

    const [clientShow, setClientShow] = useState(null);

    const [clientDelete, setClientDelete] = useState(null);

    const {user} = useContext(AuthContext);

    const [modal, setModal] = useState({

        addClient: false,
        updateClient: false,
        deleteClient: false,
    })

    useEffect(()=>{

        loadClients();
    },[])

    async function loadClients()
    {
        const clients  = await getClients(user.id);

        setClientsList(clients);

        console.log(clients);
    }

    async function show(id)
    {
        const result = await findClient(id);

        console.log(result);

        setClientShow(result);

        setModal({
            ...modal,
            updateClient:true
        })
    }

    function destroy(item)
    {
        setClientDelete(item);

        setModal({
            ...modal,
            deleteClient: true
        });
    }

    const openWhatsapp = (number) => {
        // Substitua pelo número de telefone, garantindo o código do país sem adicionar zeros, parênteses ou traços
        // Neste caso, '55' é o código do país para o Brasil seguido do DDD e do número de telefone
        window.open(`https://api.whatsapp.com/send?phone=${number}`, '_blank');
    };


    return(
        <div className="client-container">

            {sideBarOpen &&
                <SideBar 
                    sideBarOpen={sideBarOpen} 
                    setSideBarOpen={setSideBarOpen}
                />
            }

            <div className="header">

                <button onClick={()=>{setSideBarOpen(true)}} className="btn-transparent w-50px h-50px">
                    <BsJustify size={25} color="#fff" />
                </button>

                <h3>
                    Clientes
                </h3>

                <div className="btn-transparent">
                    <BsFillPeopleFill size={25} color="#fff" />
                </div>

            </div>

            <div className="content-list mt-50">
                {clientsList.map((item, index)=>{
                        return(
                                <div key={index} className="card-list mt-20">
                                    <div className="item">
                                        <p>{item.name}</p>
                                        <span>{item.phone}</span>
                                    </div>
                                    <div className="item">
                                        <button onClick={()=>{openWhatsapp("55" + item.phone)}}>
                                            <FaWhatsapp size={20} color="#000" />
                                        </button>
                                        <button onClick={(e)=>{show(item.client_id)}}>
                                            <BsPencilSquare size={20}/>
                                        </button>
                                        <button onClick={()=>{destroy(item)}}>
                                            <BsTrash size={20}/>
                                        </button>
                                    </div>
                                </div>
                        )
                    })}
            </div>
            <div className="btn-add">
                <button onClick={()=>{setModal((modal)=>({...modal, addClient:true}))}}>
                    <p><span>+</span>Adicionar cliente</p>
                </button>
            </div>

            {modal.addClient === true && <ModalAddClient

                setModal={setModal} 
                user={user}
                setClientsList={setClientsList}
            />}

            {modal.updateClient=== true && <ModalUpdateClient
                setModal={setModal} 
                user={user}
                setClientsList={setClientsList}
                clientShow={clientShow}
                setClientShow={setClientShow}
            />}

            {modal.deleteClient === true && <ModalDeleteClient
                setModal={setModal} 
                user={user}
                setClientsList={setClientsList}
                setClientDelete={setClientDelete}
                clientDelete={clientDelete}
            />}

            

        </div>
    )
}