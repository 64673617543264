import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postAuthLogin } from "./functions";
import AuthContext from "../../context/AuthContext";
import { SpinnerCircular } from "spinners-react";
import './styles/css/styles.css';
import axios from "axios";



export default function Login()
{

    const [texts, setTexts] = useState({
        header: '',
    })

    const {login, setUser} = useContext(AuthContext);

    const [check, setCheck] = useState({
        keepLoggedIn: false
    })

    const [auth, setAuth] = useState({
        email:'',
        password:''
    })

    const [errors, setErrors] = useState({
        email:'',
        password:''
    })

    const [sppiner, setSpinner] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        handleText();
     },[])

     async function handleText()
     {
        let response;

        response = await axios.get('https://api.jadde.com.br/api/text-page?name=login');

        if(response && response.data && response.data.content)
            {
                setTexts((prev)=>({
                    ...prev,
                    header: response.data.content.header,
                }))
            }
     }

    async function handleLogin()
    {
        setSpinner(true);

        if(auth.email === '')
        {
            setErrors((errors)=>({...errors, email: 'Email não pode ser vazio!'}))
            setSpinner(false);
            return
        }
        if(!auth.email.includes('@'))
        {
            setErrors((errors)=>({...errors, email: 'Email com formato inválido!'}))
            setSpinner(false);
            return
        }
        if(auth.password === '')
        {
            setErrors((errors)=>({
                ...errors, 
                email: '',
                password: 'Senha não pode ser vazio!'
            }))
            setSpinner(false);
            return
        }
        if(auth.password.length < 8)
        {
            setErrors((errors)=>({
                ...errors, 
                email: '',
                password: 'Senha não pode ser menor que 8 caracteres!'
            }))
            setSpinner(false);
            return
        }

        const response = await postAuthLogin(auth.email, auth.password);

        setSpinner(false);

        if(response)
        {
            if(response.error)
            {
                setErrors((errors)=>({
                    ...errors, 
                    email: response.error,
                    password: response.error
                }))

                return
            }

            localStorage.setItem('TOKEN_APP_AUTH_JADDE', JSON.stringify(response));

            setUser(response.person)

            // Usando postMessage para enviar dados para o aplicativo React Native
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'loginSuccess',
                    data: response.person
                }));
            }

            login(true);
        }

    }

    return(

        <div className="login-container">
            <div className="header">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: texts.header}} />
                </div>
                <img src="images/jadde_presentation_two.png"/>
            </div>

            <div className="container-form-auth">

                <div className="container-center">
                    <input onChange={(e)=>{setAuth((auth)=>({...auth, email:e.target.value}))}} type="email" className="input-form mt-50" placeholder="Digite seu email"/>
                </div>

                <div className="alert-red">
                    <span>{errors.email}</span>   
                </div>

                <div className="container-center">
                    <input onChange={(e)=>{setAuth((auth)=>({...auth, password:e.target.value}))}} type="password" className="input-form mt-20" placeholder="Digite sua senha"/>
                </div>

                <div className="alert-red">
                    <span>{errors.password}</span>  
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{handleLogin()}} className="btn-form">
                        {sppiner === true ? <SpinnerCircular size={20}/> : 'Entrar'}
                    </button>
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{navigate('/register')}} className="btn-form-outline">
                         Registrar
                    </button>
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{navigate('/redefine-password')}} className="btn-transparent">
                         Esqueceu sua senha?
                    </button>
                </div>

            </div>
        </div>
    )
}