
import { useContext, useEffect, useState } from 'react';
import AuthContext from "../../context/AuthContext";
import Admin from './containers/admin';
import Collaborators from './containers/collaborators';
import './styles/css/styles.css'




export default function Home()
{
    const {user, setUser, logout} = useContext(AuthContext);

    if(user.type !== 'admin')
    {
        return(
            <Collaborators user={user}/>
        )
    }else{
        return(
            <Admin/>
        )
    }


}