import { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsBriefcase, BsCaretRightFill, BsFillPlusCircleFill, BsHourglass, BsJustify, BsPencilSquare, BsTrash } from "react-icons/bs";
import SideBar from "../../../componentes/sidebar";
import { findService, getServices } from "../functions";
import AuthContext from "../../../context/AuthContext";
import ModalAddServices from "./containers/modalAddServices";
import ModalUpdateServices from "./containers/modalUpdateService";
import ModalDeleteService from "./containers/modalDeleteService";

export default function Services({setPages})
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const [servicesList, setServicesList] = useState([]);

    const [serviceShow, setServiceShow] = useState(null);

    const [serviceDelete, setServiceDelete] = useState(null);

    const [modal, setModal] = useState({
        addServices: false,
        updateService: false,
        deleteService: false,
    })

    const {user} = useContext(AuthContext);

    useEffect(()=>{
        loadServices();
    },[])

    async function loadServices()
    {
        const result  = await getServices(user.id);

        setServicesList(result);

        console.log(result);
    }

    async function show(id)
    {
        const service = await findService(id);

        setServiceShow(service);

        setModal({
            ...modal,
            updateService: true
        })

        console.log(service);
    }

    function destroy(service)
    {
        setServiceDelete(service);
        setModal({
            ...modal,
            deleteService: true
        });
    }

    return(

        <div className="services-container">

            <div className="header">

                <div onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu: true, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: false
                    
                    }))}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>
                <div>
                    <h3>Expediente</h3>
                </div>
                <div>
                    <BsBriefcase size={25} color="#fff" />
                </div>

            </div> 

            <div className="content-list mt-50">

                {servicesList.map((item, index)=>{
                    return(
                            <div key={index} className="card-list mt-20">
                                <div className="item">
                                    <p>{item.name}</p>
                                    <span>{item.value} - {item.duration} min</span>
                                </div>
                                <div className="item">
                                    <button onClick={(e)=>{show(item.id)}}>
                                        <BsPencilSquare size={20}/>
                                    </button>
                                    <button onClick={()=>{destroy(item)}}>
                                        <BsTrash size={20}/>
                                    </button>
                                </div>
                            </div>
                    )
                })}

            </div>



            <div className="btn-add">

                <button onClick={()=>{setModal((modal)=>({...modal, addServices:true}))}}>
                    Adicionar serviço <BsFillPlusCircleFill size={15} color={"#fff"}/>
                </button>

                {servicesList.length > 0 && <button 

                onClick={()=>{setPages((pages)=>({

                    ...pages, 
                    menu:false, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: true
                    
                    }))}}

                >

                Configurar colaboradores <BsCaretRightFill size={20} color={"#fff"}/></button>}



                <div className="mt-100"></div>

            </div>

            {modal.addServices === true && <ModalAddServices 
                setModal={setModal} 
                user={user}
                setServicesList={setServicesList}
                setPages={setPages}
            />}

            {modal.updateService === true && <ModalUpdateServices
                setModal={setModal} 
                user={user}
                setServicesList={setServicesList}
                serviceShow={serviceShow}
            />}

            {modal.deleteService === true && <ModalDeleteService
                setModal={setModal} 
                user={user}
                setServicesList={setServicesList}
                serviceDelete={serviceDelete}
                setServiceDelete={setServiceDelete}
            />}
  

        </div>
    )
}