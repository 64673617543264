import { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsCaretRightFill, BsDatabaseFillCheck, BsHourglass, BsJustify, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { DAYS_WEEK, SERVICE_INTERVALS } from "../constants";
import InputMask from "../../../lib/InputMask";
import { getOfficeHour, postOfficeHour, validateForm } from '../functions.js'
import { SpinnerCircular } from "spinners-react";
import AuthContext from "../../../context/AuthContext.js";
import ModalRepeatOfficeHours from "./containers/modalRepeatOfficeHours.js";

export default function OfficeHour({setPages})
{

    const {user} = useContext(AuthContext);

    const [sideBarOpen, setSideBarOpen] = useState(false);

    const [success, setSuccess] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        isOpen:false
    });

    const [sppiner, setSppiner] = useState(false);

    const [formControl, setFormControl] = useState({
        serviceIntervals: 15,
        checkedCloseOnHolidays: false,
        dayslimited: ''
    })

    function applyFirstDayTimesToAll() {
        // Pega os valores de `start` e `end` do primeiro objeto do array
        const { start, end } = formTime[0];
    
        // Atualiza o estado `formTime`, aplicando esses valores a todos os objetos
        setFormTime(formTime.map(day => ({
            ...day,
            start: start,
            end: end
        })));
    }
    

    const [formTime, setFormTime] = useState([
        {name: 'SEG', start: '', end: ''},
        {name: 'TER', start: '', end: ''},
        {name: 'QUA', start: '', end: ''},
        {name: 'QUI', start: '', end: ''},
        {name: 'SEX', start: '', end: ''},
        {name: 'SÁB', start: '', end: ''},
        {name: 'DOM', start: '', end: ''},
    ])

    const [errors, setErrors] = useState({
        serviceIntervals: '',
        closureOnHolidays: '',
        daysHours: '',
        adminId: '',
        daysLimited: ''
    })

    // Função para validar o formato de hora
    function isValidTimeFormat(value) {
        const timeFormatRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        return timeFormatRegex.test(value);
    }

    function checkTimeValidity(formTime) {
        // Verifica se o formato de tempo do primeiro objeto é válido
        console.log(formTime)
        if (!isValidTimeFormat(formTime[0].start) || !isValidTimeFormat(formTime[0].end)) {
            return false;
        }

        // Verifica se os campos de start e end dos objetos subsequentes estão vazios
        for (let i = 1; i < formTime.length; i++) {
            if (formTime[i].start !== '' || formTime[i].end !== '') {
                if (formTime[i].start !== ' ' || formTime[i].end !== ' ') {
                    if (formTime[i].start !== null || formTime[i].end !== null) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    useEffect(() => {
   
            if (checkTimeValidity(formTime)) {
                if(isModalOpen.isOpen === false)
                {
                    setIsModalOpen({
                        isOpen: true,
                        open:true
                    }); 
                }
                
            } else {
                setIsModalOpen((prev) => ({
                    ...prev,
                    open: false
                })); // Opcional, depende se você quer fechar o modal automaticamente nesse caso
            }
      }, [formTime]);


    function changeInputForm(dayName, field, value)
    {
        const maskedValue = InputMask.time(value);

        setFormTime(prevForm => prevForm.map(day => {
            if (day.name === dayName) {
                return { ...day, [field]: maskedValue };
            }
            return day;
        }));
    }

    function changeSelectTimes(event){
        setFormControl({
            ...formControl, // Mantém o resto do estado intacto
            serviceIntervals: parseInt(event.target.value, 10), // Atualiza o serviceInterval com o novo valor
        });
    }

    async function save()
    {

        setSppiner(true)

        const DATA = {
            serviceIntervals: formControl.serviceIntervals,
            closureOnHolidays: formControl.checkedCloseOnHolidays,
            daysHours: formTime,
            adminId: user.id,
            daysLimited: formControl.dayslimited
        }

        console.log(DATA);

        const errors = {
            serviceIntervals: "Intervalo do serviço é obrigatório",
            closureOnHolidays: "Fechamento de feriados é obrigatório",
            daysHours: "Você tem que informar o expediente do estabelecimento",
            adminId: "Usuário admin não informado",
            daysLimited: "Quantidade de dias para agendamento não informada"
        };

        const validated = validateForm(DATA, errors);

        if(Object.keys(validated).length !== 0)
        {
            setErrors(validated);
            setSppiner(false)
            return
        }else{
            setErrors({
                serviceIntervals: '',
                closureOnHolidays: '',
                daysHours: '',
                adminId: '',
                daysLimited: ''
            })
        }

        const result = await postOfficeHour(DATA);

        console.log(result);

        if(result.success)
        {
            setSppiner("success");
            return
        }

        setSppiner(false)
    }

    useEffect(()=>{
        show();
    },[])

    async function show()
    {
        const result = await getOfficeHour(user.id);

        if(result)
        {
            if(result.officeHour)
            {
                const daysHours = JSON.parse(result.officeHour.days_hours);

                setFormTime(daysHours);
        
                setFormControl({
                    serviceIntervals: result.officeHour.service_interval,
                    checkedCloseOnHolidays: result.officeHour.closure_on_holidays === 1 ? true : false,
                    dayslimited: result.officeHour.days_limited_schedulings
                }); 
            }
        }
    }



    return(
        <div className="officehour-container">

            {isModalOpen.open === true && <ModalRepeatOfficeHours 
                setIsModalOpen={setIsModalOpen}
                applyFirstDayTimesToAll={applyFirstDayTimesToAll}
            
            />}

            <div className="header">

                <div onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu: true, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: false
                    
                    }))}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>

                <div>
                    <h3>Expediente</h3>
                </div>
                
                <div>
                    <BsHourglass size={25} color="#fff" />
                </div>

            </div>                

            <div className="main">

                <p>Fechamento automático aos feriados:</p>

                <div className="d-flex-start">
                    <p>Ativar/desativar</p>
                    <button onClick={()=>{setFormControl((prev) => ({...prev, checkedCloseOnHolidays: !formControl.checkedCloseOnHolidays}))}} className="btn-check">
                        {formControl.checkedCloseOnHolidays === false ? <BsToggleOff size={20} color={'#fff'}/> : <BsToggleOn size={20} color={'#fff'} /> }
                    </button>
                </div>

                <div className="container">

                    <p>Intervalos entre atendimentos</p>

                    <select value={formControl.serviceIntervals} onChange={changeSelectTimes}>
                        {SERVICE_INTERVALS.map((item, index)=>{
                            return(
                                    <option key={index} value={item.value}>{item.name}</option>
                            )
                        })}
                    </select>

                    <div className="form">
                        <div className="form-group">
                            <span className="label">Limite de dias para agendamento</span>
                            <input value={formControl.dayslimited} onChange={(e)=>{setFormControl((prev) => ({...prev, dayslimited: e.target.value}))}} className="input" placeholder="Informar a quantidade de dias"/>
                        </div>
                    </div>

                    <div className="alert-errors mt-10">
                        <span>{errors.daysLimited}</span>
                    </div>

                    <p>HORÁRIO</p>
                    
                    <div className="office-hour-form">

                        {DAYS_WEEK.map((item, index) =>{
                            return(

                                <div key={index} className="item">
                                    <span>{item.short}</span>
                                    <input 

                                        type="text" 
                                        placeholder="00:00"
                                        value={(formTime.find(formItem => formItem.name === item.short) || {}).start || ''}
                                        onChange={e => changeInputForm(item.short, 'start', e.target.value)}
                                        
                                    />
                                    <span>às</span>
                                    <input
                                        type="text" 
                                        placeholder="00:00"
                                        value={(formTime.find(formItem => formItem.name === item.short) || {}).end || ''}
                                        onChange={e => changeInputForm(item.short, 'end', e.target.value)}
                                     />
                                </div>
                            )
                        })}

                        <div className="alert-errors mt-10">
                            <span>{errors.daysHours}</span>
                        </div>

                        <button className="btn-center" onClick={()=>{save()}}>
                             {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <> Registro salvo com sucesso! <BsDatabaseFillCheck size={20}/></> :  "Salvar"}
                        </button>

                        {sppiner === "success" && <button 
                        className="btn-between"
                        onClick={()=>{setPages((pages)=>({

                            ...pages, 
                            menu:false, 
                            company:false,
                            officeHour:false,
                            services: true,
                            collaborators: false
                            
                            }))}}

                        >
                            
                            Configurar serviços <BsCaretRightFill size={20} color={"#fff"}/></button>}

                    </div>

                    <div className="mt-100"></div>

                </div>
               
            </div>     

        </div>
    )
}