
import { useEffect, useState } from "react";
import { CardGradient, ContainerFull, ContainerFullCenter, GlobalContainer } from "./styled";
import { ButtonTransparent, Text } from "../../share/componets/styles";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteScheduling, formarHourScheduling, formatDateCardSchedulings, formatDayScheduling, getSchedulingByEmailByPhone, listServices } from "./functions";
import Reload from "../../share/componets/reload";
import ModalCancel from "./modal/modal-cancel";



export function MyScheduling()
{

    const navigate = useNavigate();

    const location = useLocation();

    const { code, email, phone } = location.state || {};

    const [itemDelete, setItemDelete] = useState(null);

    const [reload, setReload] = useState(false);

    const [data, setData] = useState({
        name:'',
        phone:'',
        email:'',
        schedulings:[]
    })

    const [modalCancel, setModalCancel] = useState(false);

    useEffect(()=>{

        handleLocalStorageData();
    
    }, [])
    
   async function handleLocalStorageData()
    {

        const chatDataStorage = JSON.parse(localStorage.getItem('chat'));

    
        if (chatDataStorage) {

            const schedulingsData = await getSchedulingByEmailByPhone(email, phone);

            let schedulings = chatDataStorage.schedulings;

            console.log(schedulings);

            console.log(schedulingsData);
            
            if(schedulings.length !== schedulingsData.length) {
                // Zera o array de schedulings para garantir que ele esteja vazio antes de adicionar novos dados
                chatDataStorage.schedulings = [];
            
                schedulingsData.forEach(element => {
                    let data = {
                        code: element.code,
                        day: formatDayScheduling(element.appointment_date),
                        hour: formarHourScheduling(element.hour),
                        status: element.status,
                        services: element.services
                    };
            
                    // Agora adiciona ao array recém-limpo
                    chatDataStorage.schedulings.push(data);
                });
            
                // Salva a chatDataStorage atualizada no localStorage
                localStorage.setItem('chat', JSON.stringify(chatDataStorage));
            }
            

    
            setData((prevData) => ({
                
                ...prevData, 
                name: chatDataStorage.name,
                phone: chatDataStorage.phone,
                email: chatDataStorage.email,
                schedulings: schedulings
            }))
    
            return true;
    
        } else {
    
            return false;
        }
    }

    async function selectDelete(str)
    {

        setItemDelete(str);

        setModalCancel(true)

    }



    return(

        <GlobalContainer>

            { reload && <Reload bg='rgba(0,0,0,0.7)'/>}

            {modalCancel === true && <ModalCancel 
            
                setReload={setReload} 
                itemDelete={itemDelete} 
                setData={setData}
                setModalCancel={setModalCancel}
                handleLocalStorageData={handleLocalStorageData}

            />}

            <ContainerFull pl='30px' mt='20px'>

                <ButtonTransparent onClick={()=>{navigate(`/scheduling?code=${code}`)}}>
                    <GoArrowLeft size={30} color="#fff" />
                </ButtonTransparent>

            </ContainerFull>

            <ContainerFull pl='30px'>
                <Text fs='20px' fcolor='#fff'>Meus Agendamentos</Text>
            </ContainerFull>

            {data.schedulings.length === 0 &&
                <ContainerFullCenter mt='50px'>
                    <Text fs='20px' fcolor='#fff'>Você não tem nunhum agendamento.</Text>
                </ContainerFullCenter>            
            }

            {data.schedulings.map((item, index) => {

                return(

                    <ContainerFullCenter key={index} mt={index === 0 ? '50px' : '20px'}>

                        <CardGradient 

                            lgl={item.status === 'Pendente' ? '#047C54'  :  '#64748b'} 
                            lgr={item.status === 'Pendente' ? '#00B774'  :  '#475569'} 
                        >

                            <Text ml='20px' fcolor='#fff' fs='12px'>{formatDateCardSchedulings(item.day, item.hour)}, {listServices(item.services)}</Text>

                            {item.status === 'Pendente' &&

                                <ButtonTransparent onClick={()=>{selectDelete(item.code)}} mr='20px'>
                                    <Text fs='12px' fw='bold' fcolor='red'>Cancelar</Text>
                                 </ButtonTransparent>
                            }


                        </CardGradient>

                    </ContainerFullCenter> 
                )
            })}


        </GlobalContainer>

        
    )
}