import { useContext, useEffect, useState } from "react";
import { BsApp, BsCheckSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { register, verifieldName } from "./function";
import { SpinnerCircular } from "spinners-react";
import AuthContext from "../../context/AuthContext";
import './styles/css/styles.css';
import InputMask from "../../lib/InputMask";
import axios from "axios";

export default function Register()
{
    const {login, setUser} = useContext(AuthContext);

    const [texts, setTexts] = useState({
        header: '',
        main_line_1: '',
        main_line_2: '',
        main_line_3: '',
        main_line_4: '',
    })

    const [check, setCheck] = useState({
        keepLoggedIn: false
    })

    const [form, setForm] = useState({
        name:'',
        email:'',
        phone:'',
        password:'',
        repassword:''
    })

    const [errors, setErrors] = useState({
        name:'',
        email:'',
        phone:'',
        password:'',
        repassword:'',
        keepLoggedIn:''
    })

    const [sppiner, setSpinner] = useState(false)

    const [modal, setModal] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        handleText();
     },[])

     async function handleText()
     {
        let response;

        response = await axios.get('https://api.jadde.com.br/api/text-page?name=register');

        if(response && response.data && response.data.content)
        {
            setTexts((prev)=>({
                ...prev,
                header: response.data.content.header,
                main_line_1: response.data.content.main_line_1,
                main_line_2: response.data.content.main_line_2,
            }))
        }
     }

    async function handleRegister()
    {
        setSpinner(true);

        if(form.name === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'Nome não pode ser vazio',
                phone:'',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(verifieldName(form.name) === false)
        {
            setErrors((errors)=>({
                ...errors, 
                name:'Informe seu nome completo',
                phone:'',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.email === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                password:'',
                repassword:'',
                email: 'Email não pode ser vazio!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(!form.email.includes('@'))
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                password:'',
                repassword:'',
                email: 'Email com formato inválido!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.phone === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'Número do whatsapp não pode ser vazio!',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password === '')
        {
            setErrors((errors)=>({
                ...errors, 
                email: '',
                name:'',
                phone:'',
                repassword:'',
                password: 'Senha não pode ser vazio!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password.length < 8)
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                email: '',
                password: 'Senha não pode ser menor que 8 caracteres!',
                repassword: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password !== form.repassword)
        {
            setErrors((errors)=>({
                ...errors,
                name:'',
                phone:'',
                email: '',
                password: 'Senha não são iguais',
                repassword: 'Senhas não são iguais',
                keepLoggedIn:''
            }));
            setSpinner(false);
            return
        }

        if(check.keepLoggedIn === false)
        {
            setErrors((errors)=>({
                ...errors,
                name:'',
                phone:'',
                email: '',
                password: '',
                repassword: '',
                keepLoggedIn:'Você tem que aceitar os termos e política de privacidade.'
            }));
            setSpinner(false);
            return
        }

        const response = await register(form);

        localStorage.setItem('TOKEN_APP_AUTH_JADDE', JSON.stringify(response));

        setUser(response.person);

        setTimeout(() => {
            login(true);
            navigate('/')
        }, 1000);

        console.log(response);

        setSpinner(false);

    }


    return(

        <div className="register-container">
            <div className="header">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: texts.header}} />
                </div>
                <img src="images/jadde_presentation_two.png"/>
            </div>

            <div className="container-form-auth">

                <div className="container-center">
                    <input value={form.name} onChange={(e)=>{setForm((form)=>({...form, name:e.target.value}))}} type="text" className="input-form mt-50" placeholder="Digite seu nome completo"/>
                </div>
                <div className="alert-red">
                    <span>{errors.name}</span>  
                </div>
                <div className="container-center">
                    <input value={form.email} onChange={(e)=>{setForm((form)=>({...form, email:e.target.value}))}} type="email" className="input-form mt-20" placeholder="Digite seu email"/>
                </div>
                <div className="alert-red">
                    <span>{errors.email}</span>  
                </div>
                <div className="container-center">
                    <input value={form.phone} onChange={(e)=>{setForm((form)=>({...form, phone: InputMask.phone(e.target.value)}))}} type="text" className="input-form mt-20" placeholder="Digite o número do seu whatsapp"/>
                </div>
                <div className="alert-red">
                    <span>{errors.phone}</span>  
                </div>
                <div className="container-center">
                    <input value={form.password} onChange={(e)=>{setForm((form)=>({...form, password:e.target.value}))}} type="password" className="input-form mt-20" placeholder="Digite sua senha com 8 dígitos"/>
                </div>
                <div className="alert-red">
                    <span>{errors.password}</span>  
                </div>
                <div className="container-center">
                    <input value={form.repassword}  onChange={(e)=>{setForm((form)=>({...form, repassword:e.target.value}))}} type="password" className="input-form mt-20" placeholder="Repita sua senha com 8 dígitos"/>
                </div>
                <div className="alert-red">
                    <span>{errors.repassword}</span>  
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{setModal(true)}} className="btn-transparent btn-form-auth">
                         {check.keepLoggedIn === false ? <BsApp /> : <BsCheckSquare />}
                         <span>Aceitar termos e política de privacidade</span>
                    </button>
                </div>

                <div className="alert-red">
                    <span>{errors.keepLoggedIn}</span>  
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{handleRegister()}} className="btn-form">
                        {sppiner === true ? <SpinnerCircular size={20}/> : 'Registrar'}
                    </button>
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{navigate('/login')}} className="btn-form-outline">
                         Entrar
                    </button>
                </div>

            </div>

            <div className={modal === true ? "modal-terms" : "hidden"}>

                <div className="modal-terms-header">
                    <div dangerouslySetInnerHTML={{ __html: texts.main_line_1}} />
                </div>

                <div className="modal-terms-content">
                    <div dangerouslySetInnerHTML={{ __html: texts.main_line_2}} />
                </div>

                <div className="modal-terms-footer">

                    <div className="container-center mt-20">
                        <button onClick={()=>{setModal(false); setCheck((prev)=>({...prev, keepLoggedIn:true}))}} className="btn-form">
                            Aceitar
                        </button>
                    </div>

                    <div className="container-center mt-20">
                        <button onClick={()=>{navigate('/login')}} className="btn-form-outline">
                            Entrar
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}