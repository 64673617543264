export default function Address()
{
    return(
        <div className="address-form">

            <p>Rua/Av</p>
            <input type="text" placeholder="Digite o nome de sua rua ou avenida"/>

            <p>Bairro</p>
            <input type="text" placeholder="Digite o nome de seu bairro"/>

            <p>Número</p>
            <input type="text" placeholder="Digite o número"/>

            <p>Estado</p>
            <input type="text" placeholder="Digite o estado"/>

            <p>Cidade</p>
            <input type="text" placeholder="Digite a cidade"/>

            <button>Salvar</button>

        </div>
    )
}