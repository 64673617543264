import { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsCaretRightFill, BsFillPeopleFill, BsFillPlusCircleFill, BsJustify, BsPencilSquare, BsTrash } from "react-icons/bs";
import { findCollaborator, getCollaborators, getOfficeHour, getServices } from "../functions";

import AuthContext from "../../../context/AuthContext";
import SideBar from "../../../componentes/sidebar";
import ModalAddCollaborator from "./containers/modalAddColaborator";
import ModalUpdateCollaborator from "./containers/modalUpdateCollaborator";
import ModalDeleteCollaborator from "./containers/modalDeleteCollaborator";
import { useNavigate } from "react-router-dom";


export default function Collaborators({setPages})
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const [collaboratorsList, setCollaboratorsList] = useState([]);

    const [servicesList, setServicesList] = useState([]);

    const [collaboratorShow, setCollaboratorShow] = useState(null);

    const [collaboratorDelete, setCollaboratorDelete] = useState(null);

    const [dayHours, setDayHours] = useState(null);

    const {user} = useContext(AuthContext);

    const navigate = useNavigate();

    const [modal, setModal] = useState({

        addCollaborator: false,
        updateCollaborator: false,
        deleteCollaborator: false,
    })


    useEffect(()=>{
        loadCollaborators();
        loadServices();
        loadHourOffice();
    },[])

    async function loadHourOffice()
    {
        const result  = await getOfficeHour(user.id);

        if(result.officeHour)
        {
            const dayHourdJSON = JSON.parse(result.officeHour.days_hours)
            setDayHours(dayHourdJSON);
        }
    }

    async function loadCollaborators()
    {
        const collaborators  = await getCollaborators(user.id);

        setCollaboratorsList(collaborators);

        //console.log(collaborators);

    }

    async function loadServices()
    {
        const result  = await getServices(user.id);

        setServicesList(result);

       // console.log(result);
    }

    async function show(id)
    {
        const result = await findCollaborator(id);

        setCollaboratorShow(result.colaborator);

        setServicesList(result.services);

        setTimeout(() => {

            setModal({
                ...modal,
                updateCollaborator:true
            })

        }, 1000);
    }

    function destroy(item)
    {
        setCollaboratorDelete(item);

        setModal({
            ...modal,
            deleteCollaborator: true
        });
    }

    return(
        <div className="collaborators-container">

            <div className="header">

                <div onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu: true, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: false
                    
                    }))}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>

                <div>
                    <h3>Colaboradores</h3>
                </div>

                <div>
                    <BsFillPeopleFill size={25} color="#fff" />
                </div>

            </div>

            <div className="content-list mt-50">
                {collaboratorsList.map((item, index)=>{
                        return(
                                <div key={index} className="card-list mt-20">
                                    <div className="item">
                                        <p>{item.name}</p>
                                        <span>{item.phone}</span>
                                    </div>
                                    <div className="item">
                                        <button onClick={(e)=>{show(item.id)}}>
                                            <BsPencilSquare size={20}/>
                                        </button>
                                        <button onClick={()=>{destroy(item)}}>
                                            <BsTrash size={20}/>
                                        </button>
                                    </div>
                                </div>
                        )
                    })}
            </div>
            <div className="btn-add">

                {servicesList.length > 0 && <button 

                    onClick={()=>{setPages((pages)=>({

                        ...pages, 
                        menu:false, 
                        company:false,
                        officeHour:false,
                        services: false,
                        collaborators: false
                        
                        })); navigate('/');}}

                    >

                    Ir para agenda... <BsCaretRightFill size={20} color={"#fff"}/></button>}  

                <button onClick={()=>{setModal((modal)=>({...modal, addCollaborator:true}))}}>
                    Adicionar colaboradores <BsFillPlusCircleFill size={15} color={"#fff"}/>
                </button>


                <div className="mt-100"></div>

            </div>

            {modal.addCollaborator === true && <ModalAddCollaborator 
                setModal={setModal} 
                user={user}
                setCollaboratorsList={setCollaboratorsList}
                servicesList={servicesList}
                dayHours={dayHours}
            />}

            {modal.updateCollaborator === true && <ModalUpdateCollaborator 
                setModal={setModal} 
                user={user}
                setCollaboratorsList={setCollaboratorsList}
                servicesList={servicesList}
                collaboratorShow={collaboratorShow}
                dayHours={dayHours}
            />}

            {modal.deleteCollaborator === true && <ModalDeleteCollaborator
                setModal={setModal} 
                user={user}
                setCollaboratorsList={setCollaboratorsList}
                setCollaboratorDelete={setCollaboratorDelete}
                collaboratorDelete={collaboratorDelete}
            />}

        </div>

      
    )
}