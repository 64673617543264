import { useContext, useEffect, useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { MdCheckBoxOutlineBlank, MdCheckCircle } from "react-icons/md";
import { SpinnerCircular } from "spinners-react";
import InputMask from "../../../lib/InputMask";
import { getCollaborator, postRecurrence } from "../api";
import Validator from "../../../lib/Validator";
import AuthContext from "../../../context/AuthContext";

export default function ModalStore({setModal, data, loadRecurrences})
{

    const [sppiner, setSpinner] = useState(false);

    const [servicesList, setServicesList] = useState([]);

    const [servicesChecked, setServicesChecked] = useState([]);

    const {user} = useContext(AuthContext);

    const [form, setForm] = useState({

        client: null,
        collaborator: null,
        services: [],
        value: '',
    });

    const [errors, setErrors] = useState({
        
        client: '',
        collaborator: '',
        services: '',
        value: '',
    })

    function loadServices(id)
    {
        const numericId = Number(id);
      
        const collaborator = data.collaborators.find(item => item.id === numericId);

        if(collaborator)
        {
            const services = JSON.parse(collaborator.services);

            setServicesList(services);

            setForm((prev) => ({...prev, collaborator: id}));

            return
        }

        setServicesList([]);
        setForm((prev) => ({...prev, collaborator: null}));
    }

    useEffect(()=>{
       user.type !== "admin" && loadServicesCollaboratorLogin();
    }, [])

    async function loadServicesCollaboratorLogin()
    {
        const response = await getCollaborator(user.id);

        console.log(response);

        const services = JSON.parse(response.colaborator.services);

        setServicesList(services);

        setForm((prev) => ({...prev, collaborator: response.colaborator.id}));
    }

    function changeServicesChecked(item) {
        setServicesChecked(prev => {
            if (prev.includes(item)) {
                // Se o item já estiver no array, retira-o
                return prev.filter(i => i !== item);
            } else {
                // Se o item não estiver no array, adiciona-o
                return [...prev, item];
            }
        });
    }

    function changeValue(str)
    {
        const value = InputMask.moneybr(str);

        setForm((prev) => ({...prev, value: value}));
    }
    

    async function save()
    {

        setSpinner(true);

        const rules = {
            client:        'required',
            collaborator:  'required',
            services:      'required',
            value:         'required'
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({
            'client.required': 'Escolha um cliente.',
            'collaborator.required': 'Escolha um colaborador.',
            'services.required': 'Selecione um ou mais serviços.',
            'value.required': 'Informe um valor.',
        });

        const validationErrors = validator.validate(form);

        if (Object.keys(validationErrors).length > 0) {

            setErrors(validationErrors);
            setSpinner(false);
            return

        } else {
            
            console.log("Formulário válido, enviar dados:", form);
             
            setErrors({
                ...errors,
                client: '',
                collaborator: '',
                services: '',
                value: '',
            });
        }

        if(servicesChecked.length < 1)
        {
            setErrors({
                ...errors,
                services: 'Selecione um ou mais serviços.',
            });

            setSpinner(false);
            return
        }

        const DATA = {

            clientId: Number(form.client),
            collaboratorId: Number(form.collaborator),
            services: servicesChecked,
            totalValue: form.value,
            observation: form.observation
        }

        await postRecurrence(DATA);

        await loadRecurrences();

        setForm({
            client: null,
            collaborator: null,
            services: [],
            value: '',
        });

        setServicesChecked([])

        setModal((prev) => ({...prev, store: false}))
    }

    return(

        <div className="modal">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((prev) => ({...prev, store: false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3 className='mt-20'>Adicionar recorrência:</h3>

                    <div className='mt-20'></div>

                    <div className="form-group">

                        <div className="label">
                            <span className='mt-20'>Cliente:</span>
                        </div>

                        <select onChange={(e)=>{setForm((prev) => ({...prev, client: e.target.value}))}}>
                            <option>Selecione</option>
                            {data.clients.map((item, index) =>{
                                return(
                                    <option key={index} value={item.client_id}>{item.name}</option>
                                )
                            })}
                        </select>

                        <span className="alert-errors">{errors.client}</span>

                        {user.type === "admin" &&

                        <>

                            <div className="label">
                                <span className='mt-20'>Colaborador:</span>
                            </div>

                            <select onChange={(e)=>{loadServices(e.target.value)}}>
                                <option>Selecione</option>
                                {data.collaborators.map((item, index) =>{
                                    return(
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        
                        </>
                        
                        }

                        <span className="alert-errors">{errors.collaborator}</span>

                        <div className="label">
                            <span className='mt-20'>Serviços:</span>
                        </div>

                        <div className="list">
                            <ul>
                                {servicesList.map((item, index) => {
                                    return(
                                        <li key={index} onClick={()=>{changeServicesChecked(item.id)}}>
                                            <span>{item.name}</span>

                                            {servicesChecked.includes(item.id) === true ? 
                                                <MdCheckCircle size={25} color="#63b3ed" /> : 
                                                <MdCheckBoxOutlineBlank size={25} color="#000"/>
                                            }
                                            
                                        </li>
                                    )
                                })}
                            </ul>

                            <span className="alert-errors">{errors.services}</span>
                        </div>

                        <div className="label">
                            <span className='mt-20'>Valor/Mês:</span>
                        </div>

                        <input value={form.value} onChange={(e)=>{changeValue(e.target.value)}} placeholder="Digite o valor" type="text"/>

                        <span className="alert-errors">{errors.value}</span>

                        <div className="label">
                            <span className='mt-20'>Observações:</span>
                        </div>

                        <textarea value={form.observation} onChange={(e)=>{setForm((prev) => ({...prev, observation: e.target.value}))}} placeholder="Escreva algo.."/>

                        <button onClick={()=>{save()}}>
                            {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro salvo com sucesso! </> :  "Salvar"}
                        </button>

                    </div>
                    <div className="space_mobile"></div>
                </div>
            </div>
        </div>
    )
}