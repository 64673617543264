import axios from "axios";
import { API_URL } from "../../config";

export async function register(form)
{
    try {
        const response = axios.post(`${API_URL}/register-web`, form);
        return (await response).data;
    } catch (error) {
        console.log(error)
    }
}

export function verifieldName(name) {
    const parts = name.trim().split(/\s+/); // Usa expressão regular para dividir por um ou mais espaços
    return parts.length >= 2 && parts.every(part => part.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]+$/));
    // Verifica se cada parte contém apenas letras (inclui letras acentuadas)
}

