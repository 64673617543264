import { ButtonTransparent, Spacing, Text } from "../../share/componets/styles";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { 

    ButtonScheduling,
    Container,
    ContainerCenter,
    ContainerEnd,
    ContainerHorizontal,
    ContainerStart,
    GlobalContainer,
    InputScheduling,
    CardServices,
    ContainerFlex,
    CardDays,
    ContainerRoudend,
    CardTime,
    StyledTelefoneInput,
    ButtonMySchedulingTop,
    JaddeAvatar,
    ContainerFlexBetween, 

} from "./styled";

import { 
    formatarData, 
    generateDaysList, 
    genereteTimes,  
    getCode, 
    getData, 
    getRecurrenceByClientId, 
    getSchedulingByEmailByPhone, 
    listServices, 
    storeScheduling, 
    transformStringDate, 
    verifieldCheckBoxHour 
} from "./functions";

import Reload from "../../share/componets/reload";

import { Concluded } from "./container/concluded";
import Block from "./container/block";

export function Scheduling()
{
    const navigate = useNavigate();

    const [reload, setReload] = useState(true);

    const [concluded, setConcluded] = useState(false);

    const [block, setBlock] = useState(false);

    const [states, setStates] = useState({

        checkboxservices: [],
        servicesList: [],
        daysList: [],
        checkboxday: null,
        checkBoxHour:null,
        collaborator: {name:''},
        times: [],
        schedulingResult: null,
        hour: null,
        schedulings:[],
        collaboratorDaysHours:[],
        collaboratorIntervals:[],
        officeHour: {},
        code: null
    })

    const [visible, setVisible] = useState({

        lineOne:false,
        lineOne1:false,

        lineTwo:false,
        lineThree:false,
        lineFour:false,
        lineTwo1:false,
        lineThree1:false,
        lineFour1:false,
        lineTwo2:false,
        lineThree2:false,
        lineFour2:false,

        lineFive:false,
        lineFive1: false,
        lineFive2: false,

        lineSix:false,
        lineSix1:false,
        lineSix2:false,

        lineSixData:false,
        lineSixRemake:false,
//RECORRENCIA START
        recurrenceStart:false,
//RECORRENCIA END
        lineSeven:false,
        lineEight:false,
        lineNine:false,
        lineTen:false,
        lineTenBloqued:false,
        lineEleven:false,
        lineTwelve:false,
        lineThirteen:false,
        lineThirteenButton:false,
        lineThirteenMensage:false,
        lineFourteen:false,
        lineFourteenButton:false,
        lineFifteen:false,
        lineSixteen:false,
        lineSeventeenData:false,
        lineSeventeen:false,
    })

    const [form, setForm] = useState({
        name:'',
        phone: '',
        email:''
    })

    const scrollRef = useRef(null);

    async function fethData()
    {
        const code = getCode();

        console.log('///////////code///////////');
        console.log(code);
        console.log('///////////code///////////');

        if(code)
        {
          
            const DATA = await getData(code);

            console.log('///////////DATA///////////');
            console.log(DATA);
            console.log('///////////DATA///////////');

            if(DATA)
            {
                const collaboratorDaysHours = JSON.parse(DATA.collaborator.days_hours);
                const servicesList = DATA.services;
                const daysList = generateDaysList(collaboratorDaysHours);
                const collaborator = DATA.collaborator;
                const schedulings = DATA.schedulings;
                const officeHour = DATA.officeHour;
                const intervals = DATA.collaborator.intervals ? JSON.parse(DATA.collaborator.intervals) : [];
        
                setStates(prevStates => ({

                    ...prevStates,
                    servicesList,
                    daysList,
                    collaborator,
                    schedulings,
                    collaboratorDaysHours,
                    officeHour,
                    code,
                    intervals
                }));
        
                setReload(false);
    
                const verifieldData = handleLocalStorageData();
    
                if(verifieldData)
                {
        
                    setTimeout(() => {
                        setVisible((prevVisible) => ({ ...prevVisible, lineSixData: true }));
                    }, 2000);
        
                    if("Notification" in window)
                    {
                        if (Notification.permission === "granted") {
            
                            setTimeout(() => {
                                setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: true }));
                            }, 3000);
                            
                        }else{
            
                            setTimeout(() => {
                                setVisible((prevVisible) => ({ ...prevVisible, lineSeven: true }));
                            }, 3000);
            
                            setTimeout(() => {
                                setVisible((prevVisible) => ({ ...prevVisible, lineEight: true }));
                                setVisible((prevVisible) => ({ ...prevVisible, lineNine: true }));
                            }, 4000);
                        }
                    }else{
        
                        setTimeout(() => {
                            setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: true }));
                        }, 3000);
                    }
        
                    return
                }
        
                setTimeout(() => {
                    setVisible((prevVisible) => ({ ...prevVisible, lineOne: true }));
                }, 2000);

                setTimeout(() => {
                    setVisible((prevVisible) => ({ ...prevVisible, lineOne1: true }));
                }, 3000);
        
                setTimeout(() => {
                    setVisible((prevVisible) => ({ ...prevVisible, lineTwo: true }));
                }, 4000);
        
                setTimeout(() => {

                    setVisible((prevVisible) => (
                        { 
                            ...prevVisible, 
                            lineThree: true,
                            lineFour: true 
                        }
                    ));
   
                }, 5000);
            }
        }
    }

    useEffect(()=>{

      fethData();

    },[])

    function handleLocalStorageData()
    {
        const chatData = JSON.parse(localStorage.getItem('chat'));

        if (chatData) {

            setForm((prevForm) => ({
                ...prevForm, 
                name: chatData.name,
                phone: chatData.phone,
                email: chatData.email
            }))

            return true;

        } else {

            console.log('Nenhum dado de chat encontrado.');

            return false;
        }
    }


    function sendName()
    {
        setVisible((prevVisible) => ({ 
            ...prevVisible, 
            lineThree: false,
            lineFour: false,
            lineFive: true 
        }));

        setTimeout(() => {

            setVisible((prevVisible) => ({ 
                ...prevVisible, 
                lineTwo1: true,
                lineThree1: true,
                lineFour1: true,
            }));

        }, 2000);


    }

    function sendPhone()
    {
        setTimeout(() => {

            setVisible((prevVisible) => ({ 
                ...prevVisible, 
                lineThree1: false,
                lineFour1: false,
                lineFive1: true
            }));

        }, 2000);

        setTimeout(() => {

            setVisible((prevVisible) => ({ 
                ...prevVisible, 
                lineTwo2: true,
                lineThree2: true,
                lineFour2: true,
            }));

        }, 3000);
    }

    async function sendEmail()
    {

        setTimeout(() => {

            setVisible((prevVisible) => ({ 
                ...prevVisible, 
                lineThree2: false,
                lineFour2: false,
                lineFive2: true 
            }));

        }, 2000);


        setTimeout( async() => {

            setReload(true);

            const client = await getSchedulingByEmailByPhone(form.email, form.phone);

            console.log(client)

            if(client.length !== 0)
            {
                if(client[0].client.block_at !== null)
                {
                    setBlock(true);

                    setReload(false);

                    return
                }

                const recurrence = await getRecurrenceByClientId(client[0].client_id, states.collaborator.id);

                setForm((prev) => ({
                    ...prev,
                    name: client[0].client.person.name
                }))


                const data = {
                    name: client[0].client.person.name,
                    phone: form.phone,
                    email:form.email,
                    collaborator: states.collaborator,
                    schedulings: []
                };
    
                localStorage.setItem('chat', JSON.stringify(data));

                setReload(false);

                setTimeout(() => {

                    setVisible((prevVisible) => ({ 

                        ...prevVisible, 

                        lineOne: false,
                        lineOne1: false,
                        lineTwo: false,
                        lineThree: false,
                        lineFour: false,
                        lineFive:false,
                        
                        lineTwo1:false,
                        lineThree1:false,
                        lineFour1:false,
                        lineFive1:false,

                        lineTwo2:false,
                        lineThree2:false,
                        lineFour2:false,
                        lineFive2:false

                    }));

                }, 1000);

                //RECORRENCIA START


                setTimeout(() => {

                    setVisible((prevVisible) => ({ 
                        ...prevVisible, 
                        recurrenceStart: recurrence.id ? true : false,
                        lineSixData: true,
                        lineTwelve: true,
                    }));

                }, 2000);

                
            }else{

                console.log('Não existe cliente');

                setReload(false);

                setVisible((prevVisible) => ({ 
                    ...prevVisible, 
                    lineSix: true,
                }));

                setTimeout(() => {
                    
                setVisible((prevVisible) => ({ 
                    ...prevVisible, 
                    lineTwelve: true,
                }));

                }, 1000);

            }

        }, 3000)
    }

    function permissionNotification()
    {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
            // Permissão já concedida, enviar notificações
            } else if (Notification.permission === "denied") {
            // Permissão negada, instrua o usuário a abrir as configurações do navegador
            alert("As notificações estão bloqueadas. Para ativá-las, acesse as configurações do seu navegador.");
            } else {
            // Ainda não foi solicitada permissão, solicite-a como mencionado anteriormente
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {

                    setVisible((prevVisible) => ({ ...prevVisible, lineEight: false }));
                    setVisible((prevVisible) => ({ ...prevVisible, lineNine: false }));

                    setTimeout(() => {
                        setVisible((prevVisible) => ({ ...prevVisible, lineTen: true }));
                    }, 1000);

                    setTimeout(() => {
                        setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: true }));
                    }, 2000);


                } else if (permission === "denied") {

                    setVisible((prevVisible) => ({ ...prevVisible, lineEight: false }));
                    setVisible((prevVisible) => ({ ...prevVisible, lineNine: false }));

                    setTimeout(() => {
                        setVisible((prevVisible) => ({ ...prevVisible, lineTenBloqued: true }));
                    }, 1000);

                    setTimeout(() => {
                        setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: true }));
                    }, 2000);
                }
            });
            }
        }
    }


    function shipPermissionNotification()
    {

        setVisible((prevVisible) => ({ ...prevVisible, lineEight: false }));
        setVisible((prevVisible) => ({ ...prevVisible, lineNine: false }));

        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineEleven: true }));
        }, 1000);

        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: true }));
        }, 2000);
    }

    function changeCheckBoxServices(item)
    {
        const SERVICES = [...states.checkboxservices]; 
      
        const index = SERVICES.indexOf(item);
      
        if (index === -1) {

          SERVICES.push(item);

        } else {

          SERVICES.splice(index, 1);
        }

        setStates((prevStates)=>({...prevStates, checkboxservices:SERVICES}));

        if(SERVICES.length > 0)
        {
            setTimeout(() => {
                setVisible((prevVisible) => ({ ...prevVisible, lineThirteenButton: true }));
            }, 1000);

            setTimeout(() => {
                const scrollElement = scrollRef.current;
    
                if (scrollElement) {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                }
            }, 2000);

            return
        }

        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineThirteenButton: false }));
        }, 1000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 2000);

    }


    function sendServices()
    {
 
        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineTwelve: false }));
            setVisible((prevVisible) => ({ ...prevVisible, lineThirteenButton: false }));
        }, 1000);

        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineThirteenMensage: true }));
        }, 2000);

        setTimeout(() => {
            setVisible((prevVisible) => ({ ...prevVisible, lineThirteen: true }));
        }, 3000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 4000);
    }

    function changeCheckBoxDay(item)
    {

        if(!item.available)
        {
            return
        }

        const times = genereteTimes(
            item, 
            states.collaboratorDaysHours, 
            states.schedulings, 
            states.officeHour, 
            states.intervals
        );

        if(Array.isArray(times))
        {
            setStates((prevStates)=>({...prevStates, times: times}));
            setStates((prevStates)=>({...prevStates, checkboxday:item.date}));
            setTimeout(() => {
                setVisible((prevVisible) => ({ ...prevVisible, lineFourteen: true }));
            }, 1000);
    
            setTimeout(() => {
    
                const scrollElement = scrollRef.current;
    
                if (scrollElement) {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                }
            }, 2000);
        }else{

            setStates((prevStates)=>({...prevStates, times: []}));
            setStates((prevStates)=>({...prevStates, checkboxday:item.date}));
        }

        return


    }

    function changeCheckBoxHour(item)
    {

        if(!item.available)
        {
            return
        }

        if(!verifieldCheckBoxHour(item.available, item.slot, states.checkboxday))
        {
            return
        }

        setStates((prevStates) => ({...prevStates, checkBoxHour: item.slot}));

        setTimeout(() => {
            setVisible((prevVisible) => ({...prevVisible, lineFourteenButton:true}));
        }, 1000);
    }


    function sendHour()
    {

        setTimeout(() => {

            setVisible((prevVisible) => ({ 
                ...prevVisible, 
                lineFourteenButton: false,
                lineFourteen: false,
                lineThirteen: false
            }));

        }, 1000);

        const date = formatarData(states.checkboxday);

        setStates((prevStates) => ({...prevStates, schedulingResult: `${date} às ${states.checkBoxHour}`}));

        setTimeout(() => {
            setVisible((prevVisible) => ({...prevVisible, lineSeventeen:true}));
        }, 2000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 3000);
      
        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 4000);
    }

    function remake()
    {
        setVisible((prevVisible) => ({
            ...prevVisible,
            lineOne:false,
            lineOne1:false,

            lineTwo:false,
            lineThree:false,
            lineFour:false,
            lineTwo1:false,
            lineThree1:false,
            lineFour1:false,
            lineTwo2:false,
            lineThree2:false,
            lineFour2:false,

            lineFive:false,
            lineFive1: false,
            lineFive2: false,

            lineSix:false,
            lineSix1:false,
            lineSix2:false,

            lineSixRemake:true,
            lineSeven:false,
            lineEight:false,
            lineNine:false,
            lineTen:false,
            lineTenBloqued:false,
    
            lineEleven:false,
            lineTwelve:true,
            lineThirteen:false,
            lineThirteenButton:false,
            lineThirteenMensage:false,
            lineFourteen:false,
            lineFourteenButton:false,
            lineFifteen:false,
            lineSixteen:false,
            lineSixteenData:false,
            lineSeventeenData:false,
            lineSeventeen:false,
        }));

        setStates((prevStates) => ({
            ...prevStates,
            checkboxservices: [],
            checkboxday: null,
            checkBoxHour:null,
            times: [],
            schedulingResult: null,
            hour: null,
        }))
    }


    function clear()
    {
        setVisible((prevVisible) => ({
            ...prevVisible,
            lineOne:false,
            lineOne1:false,

            lineTwo:false,
            lineThree:false,
            lineFour:false,
            lineTwo1:false,
            lineThree1:false,
            lineFour1:false,
            lineTwo2:false,
            lineThree2:false,
            lineFour2:false,

            lineFive:false,
            lineFive1: true,
            lineFive2: true,

            lineSix:false,
            lineSix1:false,
            lineSix2:false,

            lineSixData:false,
            lineSixRemake:false,
            lineSeven:false,
            lineEight:false,
            lineNine:false,
            lineTen:false,
            lineTenBloqued:false,
    
            lineEleven:false,
            lineTwelve:false,
            lineThirteen:false,
            lineThirteenButton:false,
            lineThirteenMensage:false,
            lineFourteen:false,
            lineFourteenButton:false,
            lineFifteen:false,
            lineSixteen:false,
            lineSeventeenData:false,
            lineSeventeen:false,
        }));

        setStates((prevStates) => ({
            ...prevStates,
            checkboxservices: [],
            checkboxday: null,
            checkBoxHour:null,
            times: [],
            schedulingResult: null,
            hour: null,
        }))
    }

    async function onSave() {


        setReload(true)

//STORE NUVEM

        const code = crypto.randomUUID();

        const DATA = {

            name: form.name,
            phone: form.phone,
            email:form.email,
            services: states.checkboxservices,
            day: states.checkboxday,
            time: states.checkBoxHour,
            hour: {date: transformStringDate(states.checkboxday), time:states.checkBoxHour},
            code: code,
            collaborator: states.collaborator,
            type: 'web'
        }

        const store = await storeScheduling(DATA);

        console.log(store);

//STORE LOCAL

        const newScheduling = {

            services: states.checkboxservices,
            day: states.checkboxday,
            hour: states.checkBoxHour,
            code: code,
            status: 'Pendente'      
        };

        // Tentar recuperar dados existentes do localStorage
        const existingData = localStorage.getItem('chat');
        let data;

        if (existingData) {

            // Se houver dados existentes, parseia e adiciona o novo agendamento
            data = JSON.parse(existingData);
            data.schedulings.push(newScheduling);

        } else {

            // Se não houver dados, cria uma nova estrutura de dados
            data = {
                name: form.name,
                phone: form.phone,
                email:form.email,
                collaborator: states.collaborator,
                schedulings: [newScheduling]
            };
        }

        // Salva os dados atualizados no localStorage
        localStorage.setItem('chat', JSON.stringify(data));

        setTimeout(() => {

            clear();
            setReload(false);
            setConcluded(true);

        }, 3000);

    }

    function formatDate(dateStr) {
        const [year, month, day] = dateStr.split("-");
        return `${day}/${month}`;
    }


    if(concluded)
    {
        return (
            <Concluded 
                code={states.code} 
                email={form.email} 
                name={form.name}
                collaboratorName={states.collaborator.name}
            />
        )

    }else{

        if(block)
        {
           return(
                <Block/>
           ) 
        }else{
            return(

                <GlobalContainer ref={scrollRef}>
        
                       { reload && <Reload bg='rgba(0,0,0,0.7)'/>}
        
                        <ContainerCenter visible={visible.lineOne ? 'true' : undefined}>
        
                            <ContainerStart>
        
                                <Container mt='50px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Olá, tudo bem? Sou a Jadde IA, responsável pelos agendamentos do {states.collaborator.name}</Text>
                                </Container>
        
                            </ContainerStart>
        
                        </ContainerCenter>
    
                        <ContainerCenter visible={visible.lineOne1 ? 'true' : undefined}>
        
                            <ContainerStart>
    
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Vamos conferir se você já tem cadastro em nosso sistema?</Text>
                                </Container>
    
                            </ContainerStart>
    
                        </ContainerCenter>
    
    {/*VERIFICAÇÃO DE DADOS START*/}   
    
    {/*NOME*/}   
                        <ContainerCenter visible={visible.lineTwo ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Qual o seu nome e sobrenome, por favor?</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineThree ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <InputScheduling value={form.name} onChange={(e)=>{setForm((prevForm) => ({...prevForm, name: e.target.value}))}} mt='10px' placeholder="Seu nome e sobrenome"/>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineFour ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <ButtonScheduling onClick={sendName} mt='10px'>Continuar</ButtonScheduling>
                            </ContainerStart>
                        </ContainerCenter>
    
                        <ContainerCenter visible={visible.lineFive ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>{form.name}.</Text>
                                </Container>
                             </ContainerEnd>
                        </ContainerCenter>
    {/*PHONE*/}   
                        <ContainerCenter visible={visible.lineTwo1 ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='10px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Qual o seu WhatsApp? Digite apenas os números com DDD, ok?</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineThree1 ? 'true' : undefined}>
    
                            <ContainerStart mt='10px'>
    
                                <StyledTelefoneInput value={form.phone} onChange={(e)=>{setForm((prevForm) => ({...prevForm, phone: e.target.value}))}} mask="(99) 99999-9999" maskChar=" " />;
                                
                            </ContainerStart>
    
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineFour1 ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <ButtonScheduling onClick={sendPhone} mt='10px'>Prosseguir</ButtonScheduling>
                            </ContainerStart>
                        </ContainerCenter>
    
                        <ContainerCenter visible={visible.lineFive1 ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>{form.phone}.</Text>
                                </Container>
                             </ContainerEnd>
                        </ContainerCenter>
    
    {/*EMAIL*/}  
    
                        <ContainerCenter visible={visible.lineTwo2 ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Ótimo! E qual é o seu email?</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineThree2 ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <InputScheduling value={form.email} onChange={(e)=>{setForm((prevForm) => ({...prevForm, email: e.target.value}))}} mt='10px' placeholder="Seu email"/>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineFour2 ? 'true' : undefined}>
                            <ContainerStart mt='10px'>
                                <ButtonScheduling onClick={sendEmail} mt='10px'>Continuar agora</ButtonScheduling>
                            </ContainerStart>
                        </ContainerCenter>  
    
                        <ContainerCenter visible={visible.lineFive2 ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>{form.email}.</Text>
                                </Container>
                             </ContainerEnd>
                        </ContainerCenter>
    
    {/*VERIFICAÇÃO DE DADOS END*/}    
    
    {/*CLIENTE NÃO ENCONTRADO START*/}  
    
                        <ContainerCenter visible={visible.lineSix ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Prazer, {form.name}! Parece que você ainda não realizou nunhum agendamento. Que tal escolher um ou mais dos serviços abaixo?</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
    
    
    {/*CLIENTE NÃO ENCONTRADO END*/}  
    
    
    {/*CLIENTE ENCONTRADO START*/}  
        
                        <ContainerCenter visible={visible.lineSixData ? 'true' : undefined}>
                            <ContainerEnd>
                                <ButtonMySchedulingTop onClick={()=>{navigate('/myschedulings', { state: { code: states.code, email: form.email, phone: form.phone } })}} mt='20px'>
                                    Meus agendamentos
                                </ButtonMySchedulingTop>
                            </ContainerEnd>
                        </ContainerCenter>
        
    
                        <ContainerCenter visible={visible.lineSixData ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Como vai, {form.name}! Estou feliz que voltou!</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.recurrenceStart ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Você é um cliente recorrente deste profissional.</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>

                        <ContainerCenter visible={visible.lineSixData ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Qual serviço você deseja agendar hoje?</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
    {/*CLIENTE ENCONTRADO END*/} 
    
                        <ContainerCenter visible={visible.lineSeven ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Para garantir que você não esqueça do seu agendamento, ative as notificações aqui embaixo, por favor.</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineEight ? 'true' : undefined}>
                            <ContainerStart>
                                <ButtonScheduling onClick={permissionNotification}  mt='20px'>Ativar notificações</ButtonScheduling>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineNine ? 'true' : undefined}>
                            <ContainerStart>
                                <ButtonScheduling onClick={shipPermissionNotification} lgl='#064e3b' lgr='#065f46' mt='20px'>Pular</ButtonScheduling>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineTen ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>Ativar notificações</Text>
                                </Container>
                            </ContainerEnd>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineTenBloqued ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>Bloquear notificações</Text>
                                </Container>
                            </ContainerEnd>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineEleven ? 'true' : undefined}>
                            <ContainerEnd>
                                <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                    <Text fcolor='#fff' fs='15px'>Pular</Text>
                                </Container>
                            </ContainerEnd>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineSixRemake ? 'true' : undefined}>
                            <ContainerStart>
                                <Container mt='20px'>
                                    <JaddeAvatar src="./images/jadde_menu.png"/>
                                    <Text ml='10px' fcolor='#fff' fs='15px'>Ok, {form.name}! Vamos refazer seu agendamento.</Text>
                                </Container>
                            </ContainerStart>
                        </ContainerCenter>



                        <ContainerCenter visible={visible.lineTwelve ? 'true' : undefined} mt='20px'>
                            <ContainerStart>
                                <Text fcolor='#fff' fs='15px'>SELECIONE O SERVIÇO</Text>
                            </ContainerStart>
                        </ContainerCenter>
        
                        <ContainerCenter visible={visible.lineTwelve ? 'true' : undefined}>
        
                            <ContainerHorizontal mt='10px'>
        
                                {states.servicesList.map((item, index)=>{
        
                                    return(
        
                                        <CardServices onClick={()=>{changeCheckBoxServices(item)}} key={index} checkboxservices={states.checkboxservices.includes(item)  ? 'true' : undefined}>
        
                                            <ContainerFlexBetween jc='space-between'>
    
                                                <Text ml='10px' fcolor='#fff' >{item.name}</Text>
    
                                                <ButtonTransparent mr='10px'mt='10px'>
                                                    {states.checkboxservices.includes(item)
                                                        ? <MdCheckBox size={30} color='#fff'/>
                                                        : <MdCheckBoxOutlineBlank size={30} color='#fff'/>
                                                    }
                                                    
                                                </ButtonTransparent>
    
                                            </ContainerFlexBetween >
        
    
        
                                            <ContainerFlexBetween jc='space-between' mt='10px'>
                                                <Text ml='10px' mr='10px' fcolor='#fff' >{item.value}</Text>
                                                <Text ml='10px' mr='10px'fcolor='#fff' >{item.duration} min</Text>
                                            </ContainerFlexBetween >
        
                                        </CardServices>
                                    )
                                })}
        
                            </ContainerHorizontal>
        
                        </ContainerCenter>
        
                        {states.servicesList.length > 4 &&
        
                            <ContainerCenter visible={visible.lineThirteen ? 'true' : undefined}>
                                <ContainerEnd>
                                    <Text fcolor='#fff' fs='15px'>ARRASTE PARA O LADO PARA VER</Text>
                                </ContainerEnd>
                            </ContainerCenter>
                        }
        
                    <ContainerCenter onClick={()=>{sendServices()}} visible={visible.lineThirteenButton ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling mt='15px'>Prosseguir</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineThirteenMensage ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{listServices(states.checkboxservices)}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>
        
                    <ContainerCenter mt='50px' visible={visible.lineThirteen ? 'true' : undefined}>
                        <ContainerStart mt='20px'>
                            <Text fcolor='#fff' fs='15px'>SELECIONE O DIA</Text>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineThirteen ? 'true' : undefined}>
        
                        <ContainerHorizontal mt='10px'>
        
                            {states.daysList.map((item, index)=>{
                                return(
        
                                    <CardDays 
                                    pointer={item.available ? 'pointer' : 'none'} 
        
                                    checkboxday={states.checkboxday === item.date  ? 'true' : undefined} 
        
                                    key={index} onClick={()=>{changeCheckBoxDay(item)}}
                                    isavailable={item.available  ? 'true' : undefined}
                                    >
        
                                        <ContainerFlex  bb={item.available ? '1px solid #fff' : '1px solid #ccc'} jc='center'>
                                            <Text td={item.available ? 'none' : 'line-through'} mt='5px' mb='5px' fw='bold' fcolor={item.available ? '#fff' : '#ccc'}>{item.short}</Text>
                                        </ContainerFlex>
        
                                        <ContainerFlex  jc='center'>
                                            <Text mt='5px' mb='5px' fw='bold' fcolor={item.available ? '#fff' : '#ccc'}>{formatDate(item.date)}</Text>
                                        </ContainerFlex>
                                   
                                    </CardDays>
                                    
                                )
                            })}
                        </ContainerHorizontal>
        
                    </ContainerCenter>
        
                    <ContainerCenter mt='50px' visible={visible.lineFourteen ? 'true' : undefined}>
                        <ContainerStart mt='20px'>
                            <Text fcolor='#fff' fs='15px'>SELECIONE O HORÁRIO</Text>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter mt='10px' visible={visible.lineFourteen ? 'true' : undefined}>
        
                        <ContainerFlex w='1200px'>


                            {states.times.map((item,index) =>{
                                return(
    
                                    <CardTime checkboxhour={(
    
                                        states.checkBoxHour === item.slot ? 'true' : undefined ) 
    
                                        }  
    
                                        onClick={()=>{changeCheckBoxHour(item)}} key={index} mt='10px'
                                    >
                                        <Text td={verifieldCheckBoxHour(item.available, item.slot, states.checkboxday) ? 'none' : 'line-through'} fcolor='#fff' fs='15px'>{item.slot}</Text>
                                    </CardTime>
                                )
                            })}
        
                        </ContainerFlex>
        
                    </ContainerCenter>
        
                    <ContainerCenter onClick={()=>{sendHour()}} visible={visible.lineFourteenButton ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling mt='20px'>Concluir</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineFifteen ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{states.schedulingResult}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>
        
        
        
                    <ContainerCenter visible={visible.lineSixteen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png"/>
                                <Text ml='10px' fcolor='#fff' fs='15px'>Qual é o seu whatsapp?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineSixteenData ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png"/>
                                <Text ml='10px' fcolor='#fff' fs='15px'>Seu número de whatsapp é o mesmo?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>
        
        
                    <ContainerCenter visible={visible.lineSixteen || visible.lineSixteenData ? 'true' : undefined}>
                        <ContainerStart>
                            <StyledTelefoneInput
                                mt='50px'
                                mask="(99) 99999-9999"
                                placeholder="(00) 00000-0000"
                                type="text"
                                onChange={(e)=>{setForm((prevForm) => ({...prevForm, phone: e.target.value}))}}
                                value={form.phone}
                            />
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter onClick={()=>{sendPhone()}} visible={(visible.lineSixteen ? 'true' : undefined) || (visible.lineSixteenData ? 'true' : undefined)}>
                        <ContainerStart>
                            <ButtonScheduling mt='20px'>Enviar</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>
        
        
        
                    <ContainerCenter visible={visible.lineSeventeenData ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{form.phone}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>
        
        
                    <ContainerCenter visible={visible.lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png"/>
                                <Text ml='10px' fcolor='#fff' fs='15px'>Perfeito, {form.name}!</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png"/>
                                <Text ml='10px' fcolor='#fff' fs='15px'>Confirmando agendamento: {listServices(states.checkboxservices)}, com o(a) {states.collaborator.name} no(a) {states.schedulingResult}.</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter  visible={visible.lineSeventeen ? 'true' : undefined}>
                        <ContainerStart onClick={onSave}>
                            <ButtonScheduling mt='20px'>Sim, agendar agora</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>
        
                    <ContainerCenter visible={visible.lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling onClick={remake} lgl='#064e3b' lgr='#065f46' mt='20px'>Refazer</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>
        
        
                    <Spacing space='250px'></Spacing>
        
                </GlobalContainer>
            )
        }

    }
}