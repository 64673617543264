import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import './styles/css/styles.css';
import axios from "axios";



export default function Presentation()
{
    const [page, setPage] = useState({
        item1: true,
        item2: false,
        item3: false
    })

    const [texts, setTexts] = useState({

        header: '',
        main_line_1_1: '',
        main_line_1_2: '',
        main_line_1_3: '',
        main_line_1_4: '',

        main_line_2_1: '',
        main_line_2_2: '',
        main_line_2_3: '',
        main_line_2_4: '',
        main_line_2_5: '',

        main_line_3_1: '',
        main_line_3_2: '',
        main_line_3_3: '',
        main_line_3_4: '',
    })

    const navigate = useNavigate();

    function changePage(page)
    {
        switch (page) {

            case 1:

                setPage((prev) => ({...prev, item1:true, item2:false, item3:false}))
                
                break;
            case 2:

                setPage((prev) => ({...prev, item1:false, item2:true, item3:false}))
                    
                break; 
            case 3:

                setPage((prev) => ({...prev, item1:false, item2:false, item3:true}))
                    
                break;               
                
            default:
                break;
        }
    }

    useEffect(()=>{
       handleText();
    },[])

    async function handleText()
    {
        let response;

        response = await axios.get('https://api.jadde.com.br/api/text-page?name=presentation_one');
        if(response && response.data && response.data.content)
            {
                setTexts((prev)=>({
                    ...prev,
                    header: response.data.content.header,
                    main_line_1_1: response.data.content.main_line_1,
                    main_line_1_2: response.data.content.main_line_2,
                    main_line_1_3: response.data.content.main_line_3,
                    main_line_1_4: response.data.content.main_line_4
                }))
            }
        response = await axios.get('https://api.jadde.com.br/api/text-page?name=presentation_two');

        if(response && response.data && response.data.content)
            {
                setTexts((prev)=>({
                    ...prev,
                    header: response.data.content.header,
                    main_line_2_1: response.data.content.main_line_1,
                    main_line_2_2: response.data.content.main_line_2,
                    main_line_2_3: response.data.content.main_line_3,
                    main_line_2_4: response.data.content.main_line_4,
                    main_line_2_5: response.data.content.main_line_5,
                }))
            }

        response = await axios.get('https://api.jadde.com.br/api/text-page?name=presentation_three');
    
        if(response && response.data && response.data.content)
            {
                setTexts((prev)=>({
                    ...prev,
                    header: response.data.content.header,
                    main_line_3_1: response.data.content.main_line_1,
                    main_line_3_2: response.data.content.main_line_2,
                    main_line_3_3: response.data.content.main_line_3,
                    main_line_3_4: response.data.content.main_line_4
                }))
            }
    }

    return(

        <div className="presentation-container">

            <div className="header">

                <div className="header-center">
                    <div dangerouslySetInnerHTML={{ __html: texts.header}} />
                </div>

                {page.item1 === true &&  <img src="images/jadde_presentation_one.png"/>}
                {page.item2 === true &&  <img src="images/jadde_presentation_two.png"/>}
                {page.item3 === true &&  <img src="images/jadde_presentation_three.png"/>}

            </div>

            <div className="mt-100"></div>

            <div className="text-center">

                {page.item1 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_1_1 }} />}
                {page.item2 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_2_1 }} />}
                {page.item3 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_3_1 }} />}

            </div>

            <div className="mt-50"></div>

            <div className="text-center">

            {page.item1 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_1_2}} />}
            {page.item2 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_2_2}} />}
            {page.item3 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_3_2}} />}

            </div>

            <div className="text-center">

            {page.item1 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_1_3 }} />}
            {page.item2 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_2_3 }} />}
            {page.item3 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_3_3 }} />}

            </div>

            <div className="text-center">

            {page.item1 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_1_4}} />}
            {page.item2 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_2_4}} />}
            {page.item3 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_3_4}} />}

            </div>

            <div className="text-center">

            {page.item1 === true && <p></p>}
            {page.item2 === true && <div dangerouslySetInnerHTML={{ __html: texts.main_line_2_5}} />}
            {page.item3 === true && <p> </p>}

            </div>

            <div className="text-center">
            <p></p>
            </div>

            <div className="mt-50"></div>

            <div className="container-center">

            <ul className="btn-slider">
                <li onClick={()=>{changePage(1)}} className={page.item1 === true ? "btn-item-active" : "btn-item"}></li>
                <li onClick={()=>{changePage(2)}} className={page.item2 === true ? "btn-item-active" : "btn-item"}></li>
                <li onClick={()=>{changePage(3)}} className={page.item3 === true ? "btn-item-active" : "btn-item"}></li>
            </ul>

            </div>

            <div className="mt-50"></div>

            <div className="container-center">

            {page.item1 === true && <button onClick={()=>{changePage(2)}} className="btn-transparent">Próximo</button>}

            </div>

            <div className="container-center">

            {page.item2 === true && <button onClick={()=>{changePage(3)}} className="btn-transparent">Próximo</button>}

            </div>

            <div className="container-center">

            {page.item3 === true && <button onClick={()=>{navigate('/login')}} className="btn-form">Comecar Agora</button>}

            </div>

        </div>

        
    )
}